import React from "react";
import { TitleWithDivider } from "../../../common/TitleWithDivider";

/**
 * NewCard - A reusable card component that combines a title with an optional divider and a card body.
 *
 * This component displays a title with an optional divider at the top, followed by a card structure
 * containing a header and a body section. The body section can be used to display various content such
 * as text, forms, or other components.
 *
 * @param {Object} props - The props object.
 * @param {string} props.titleText - The text to be displayed as the main title above the card.
 * @param {boolean} props.useDivider - A flag indicating whether to show a divider below the title.
 * @param {string} props.header - The text to be displayed in the card header.
 * @param {string} props.bodyLabel - A label to be displayed above the body content, providing context for the content.
 * @param {React.ReactNode} props.bodyContents - The content to be displayed in the card body. It can be any React node, such as text, forms, or other components.
 * @returns {JSX.Element} - A JSX element representing a card with a title, optional divider, header, and body content.
 *
 * Usage:
 * <NewCard
 *   titleText="Main Title"
 *   useDivider={true}
 *   header="Card Header"
 *   bodyLabel="Body Label"
 *   bodyContents={<div>Your content here</div>}
 * />
 */

export const NewCard = ({
  titleText,
  useDivider,
  header,
  bodyLabel,
  bodyContents,
}) => {
  return (
    <>
      <div>
        <TitleWithDivider titleText={titleText} useDivider={useDivider} />

        <div className="border border-text-field-border rounded-xl border-r-1 mt-10">
          <div className="w-full flex mayo-card-header border-b">
            <h3 className="p-4 label-light-blue-xl">{header}</h3>
          </div>

          <div className="mayo-card-body">
            <div className="label-light-blue-xl">{bodyLabel}</div>
            {bodyContents}
          </div>
        </div>
      </div>
    </>
  );
};
