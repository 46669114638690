import React from "react";
import { useState } from "react";

interface CreateSkillTagModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onCreateTag: (tagName: string) => void;
}

/**
 * CreateSkillTagModal - A modal component for creating a new skill tag.
 *
 * This component renders a modal dialog that allows users to input a name for a new skill tag and submit it.
 * The modal is only visible when the `isOpen` prop is true. It provides an input field for the tag name and
 * two buttons: one to submit the new tag and one to cancel/close the modal.
 *
 * @param {Object} props - The props object.
 * @param {boolean} props.isOpen - A flag indicating whether the modal is open.
 * @param {function} props.onRequestClose - A callback function to close the modal.
 * @param {function} props.onCreateTag - A callback function to handle the creation of a new skill tag, accepting the tag name as an argument.
 * @returns {JSX.Element | null} - A JSX element representing the modal if `isOpen` is true, or null if the modal is closed.
 *
 * Usage:
 * <CreateSkillTagModal
 *   isOpen={isModalOpen}
 *   onRequestClose={closeModalHandler}
 *   onCreateTag={handleCreateTag}
 * />
 */

const CreateSkillTagModal: React.FC<CreateSkillTagModalProps> = ({
  isOpen,
  onRequestClose,
  onCreateTag,
}) => {
  const [newTagName, setNewTagName] = useState<string>("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newTagName) {
      onCreateTag(newTagName);
      setNewTagName("");
      onRequestClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-opacity-50 flex items-center justify-center backdrop-brightness-50">
      <div className="border border-black-10 bg-white rounded-xl w-100 p-6">
        <h2 className="text-lg font-semibold mb-4">新スキルタグを作成</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            className="border rounded p-2 w-full"
            placeholder="スキルタグ名"
            value={newTagName}
            onChange={(e) => setNewTagName(e.target.value)}
          />
          <div className="mt-4 flex justify-end space-x-4">
            <button
              type="button"
              className="bg-gray-500 text-white px-4 py-2 rounded hover:opacity-50"
              onClick={onRequestClose}
            >
              キャンセル
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:opacity-50"
            >
              作成
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateSkillTagModal;
