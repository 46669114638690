import axios from "axios";

const useApiConnection = async () => {
  let isConnected: boolean = false;

  try {
    const response = await axios.get(
      `${import.meta.env.VITE_SYS_API_URL}/health_check/index`,
      { timeout: 5000 },
    );
    // we expect to get rejected, since we don't submit the token.
    // so 401 is a test success the the connection exists.
    // otherwise, we would be get 404.
    if (response.status == 200 || response.status === 401) {
      isConnected = true;
    } else {
      isConnected = false;
    }
  } catch (error) {
    if (error.status == 200 || error.status === 401) {
      isConnected = true;
    } else if (error.code == "ECONNABORTED") {
      return;
    }
  }

  return isConnected;
};

export default useApiConnection;
