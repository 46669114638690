import { ADMINUSER } from "../types/adminUser";

export const BlankAdminUserData: ADMINUSER = {
  tenant_id: 0,
  first_name: "",
  last_name: "",
  first_kana_name: "",
  last_kana_name: "",
  name_kana: "",
  login_id: "",
  login_password: "",
};
