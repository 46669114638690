import { TENANTDEPARTMENT } from "../types/tenantDepartment";

export const BlankTenantDepartmentData: TENANTDEPARTMENT = {
  id: "",
  name: "",
  name_kana: "",
  code: "",
  parent: null,
  children: [],
};
