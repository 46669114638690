import React, { ChangeEvent, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { TitleWithDivider } from "../../common/TitleWithDivider";
import { NewCard } from "../../components/Cards/Templates/newCard";

const MentalHealthPage: React.FC = () => {
  return (
    <>
      <div>
        <div className="flex flex-row justify-between items-center">
          <TitleWithDivider
            titleText={"メンタルヘルスケア管理"}
            useDivider={false}
          />
          <Link to="/hub/mentalhealthCare/manage/aiSettings">
            <div className="button-default">AI 1 on 1 設定</div>
          </Link>
        </div>

        <div className="mt-5 divider"></div>

        <NewCard
          header={"診断結果傾向"}
          bodyLabel={""}
          bodyContents={
            <div className="mayo-card-body flex justify-evenly">
              <div className="bg-bodydark2 text-white items-end p-4 rounded-lg cursor-pointer">
                要件未定義 （安田さんの回答待ち）
              </div>
            </div>
          }
        ></NewCard>
      </div>
    </>
  );
};

export default MentalHealthPage;
