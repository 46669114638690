import React from "react";
import { useParams } from "react-router-dom";
import { TitleWithDivider } from "../../common/TitleWithDivider.tsx";
import CreateTenantForm from "../../components/Forms/CreateTenantForm.tsx";

const CreateTenantPage: React.FC = () => {
  return (
    <>
      <div>
        <TitleWithDivider titleText="企業追加" useDivider={true} />
        <CreateTenantForm></CreateTenantForm>
      </div>
    </>
  );
};

export default CreateTenantPage;
