import { USER } from "../types/user";

const BlankUser: USER = {
  id: 0,
  uid: "",
  type: 0,
  employee_number: "",
  name: "",
  tenant_code: localStorage.getItem("realm"),
  kana_name: "",
  furigana_name: "",
  email: "",
  profile_image_url: "",
  login_id: "",
  login_password: "",
  is_display: true,
  card_image_urls: [],
  company_name: "",
  company_tel: "",
  personal_tel: "",
  company_address: "",
  company_home_page: "",
  date_of_receiving_info: "",
};

export default BlankUser;
