const utf8Replacements = [
  { pattern: /·/g, replacement: "・" },
  { pattern: /—/g, replacement: "―" },
  { pattern: /…/g, replacement: "･･･" },
  { pattern: /’/g, replacement: "'" },
  { pattern: /“/g, replacement: '"' },
  { pattern: /”/g, replacement: '"' },
  { pattern: /°/g, replacement: "゜" },
  { pattern: /•/g, replacement: "・" },
  { pattern: /—/g, replacement: "－" },
  { pattern: /~/g, replacement: "～" },
  { pattern: /é/g, replacement: "e" },
  { pattern: /à/g, replacement: "a" },
  { pattern: /ü/g, replacement: "u" },
  { pattern: /€/g, replacement: "EUR" },
  { pattern: /£/g, replacement: "GBP" },
  { pattern: /√/g, replacement: "sqrt" },
  { pattern: /∞/g, replacement: "infinity" },
  { pattern: /≠/g, replacement: "!=" },
];

export default utf8Replacements;
