import React from "react";
import LoadingIcon from "../../../common/LoadingIcon";

interface Card {
  header: string;
  bodyLabel: string;
  isTable?: boolean;
  isLoading?: boolean;
  bodyContents: React.ReactNode;
}

/**
 * NewCard - A card component for displaying content with a header and body section in the speicifed project format.
 *
 * This component provides a flexible structure to display content inside a card layout. It includes a header,
 * an optional loading state, and a body section that can accommodate various content, including tables.
 *
 * @param {Object} props - The props object.
 * @param {string} props.header - The text to be displayed in the card header.
 * @param {string} props.bodyLabel - A label to be displayed above the body contents.
 * @param {boolean} [props.isTable=false] - A flag indicating if the body contents should be styled for table display. Defaults to `false`.
 * @param {boolean} [props.isLoading=false] - A flag indicating if the card is in a loading state. When `true`, a loading icon is shown. Defaults to `false`.
 * @param {React.ReactNode} props.bodyContents - The content to be displayed in the card body. It can be any React node, such as text, tables, or other components.
 * @returns {JSX.Element} - A JSX element representing a card with a header and body content, optionally showing a loading icon.
 *
 * Usage:
 * <NewCard
 *   header="Card Header"
 *   bodyLabel="Card Body Label"
 *   isTable={true}
 *   isLoading={false}
 *   bodyContents={<div>Your content here</div>}
 * />
 */

export const NewCard: React.FC<Card> = ({
  header,
  bodyLabel,
  isTable,
  isLoading,
  bodyContents,
}) => {
  return (
    <div>
      <div className="border border-text-field-border rounded-xl border-r-1 mt-10">
        <div className="w-full flex mayo-card-header border-b">
          <h3 className="p-4 label-light-blue-xl">{header}</h3>
        </div>

        <div>
          {isLoading ? (
            <LoadingIcon />
          ) : (
            <div
              className={!isTable ? "mayo-card-body" : "bg-white rounded-xl"}
            >
              <div className="label-light-blue-xl">{bodyLabel}</div>
              {bodyContents}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
