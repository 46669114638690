const APPNAME: string = import.meta.env.VITE_APP_NAME;

export const pageTitles = {
  TENANT: {
    main: `企業一覧 | ${APPNAME}`,
    manage: `企業情報管理 | ${APPNAME}`,
    create: `企業情報作成 | ${APPNAME}`,
  },
  KAKELY: {
    main: `KAKELY | ${APPNAME}`,
    departmentView: `部署一覧表示設定 | ${APPNAME}`,
    departmentSettings: `部署一覧表示設定 | ${APPNAME}`,
    departmentCreate: `部署一覧表示設定 | ${APPNAME}`,
    phonebookEdit: `電話帳登録・編集画面 | ${APPNAME}`,
    phonebookView: `電話帳情報 | ${APPNAME}`,
  },
  YOBELY: {
    main: `YOBELY管理 | ${APPNAME}`,
  },
  USER: {
    main: `ユーザ一覧 | ${APPNAME}`,
    edit: `ユーザ情報編集| ${APPNAME}`,
    create: `ユーザ情報参照・編集 | ${APPNAME}`,
    photoUpload: `顔写真アップロード | ${APPNAME}`,
    changePassword: `パスワード変更 | ${APPNAME}`,
  },
  NOTIFICATION: {
    main: `お知らせ一覧 | ${APPNAME}`,
    create: `お知らせ作成 | ${APPNAME}`,
    edit: `お知らせ登録・編集 | ${APPNAME}`,
  },
  WORKJOY: {
    main: `HUB管理 | ${APPNAME}`,
    manageMentalCare: `メンタルヘルスケア管理 | ${APPNAME}`,
    manageAISettings: `メンタルヘルスケア管理 | ${APPNAME}`,
    manageSettings: `スタンプ管理 | ${APPNAME}`,
    biToolSettings: `BIツール連携 | ${APPNAME}`,
  },
  GENERIC: {
    main: "",
    login: `ログイン | ${APPNAME}`,
    settings: `ユーザ設定 | ${APPNAME}`,
    home: `HOME | ${APPNAME}`,
    csvUpload: `CSVアップロード | ${APPNAME}`,
    not_found: `404 - Not Found | ${APPNAME}`,
  },
};
