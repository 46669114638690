import React from "react";
import { TitleWithDivider } from "../../common/TitleWithDivider";
import CreateNotificationCard from "../../components/Cards/Notification/NewNotificationCard";

const CreateNotificationPage: React.FC = () => {
  return (
    <>
      <div>
        <TitleWithDivider titleText="お知らせ追加" useDivider={true} />
        <CreateNotificationCard />
      </div>
    </>
  );
};

export default CreateNotificationPage;
