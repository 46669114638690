import React from "react";
import { NewCard } from "../../components/Cards/Templates/newCardTitle.tsx";

const BiToolPage: React.FC = () => {
  return (
    <>
      <div>
        <NewCard
          titleText={"BIツール連携"}
          useDivider={true}
          header={"BIツール連携"}
          bodyLabel={"WIP BIツール連携"}
          bodyContents={undefined}
        ></NewCard>
      </div>
    </>
  );
};

export default BiToolPage;
