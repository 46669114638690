import { useEffect } from "react";

/**
 * useClickOutside - A custom hook for handling clicks outside a modal and closing it.
 *
 * This hook listens for mouse clicks outside the referenced modal element and keypresses of the Escape key
 * to trigger the `onRequestClose` callback, typically used to close the modal. It adds the necessary event
 * listeners when the modal is open (`isOpen` is true) and cleans up the listeners when the modal is closed.
 *
 * @param {boolean} isOpen - A flag indicating whether the modal is currently open.
 * @param {function} onRequestClose - A callback function to be called when a click outside the modal or Escape key is detected.
 * @param {React.RefObject<HTMLDivElement>} modalRef - A reference to the modal DOM element to detect clicks outside of it.
 *
 * Usage:
 * const modalRef = useRef(null);
 * useClickOutside(isOpen, handleClose, modalRef);
 */
const useClickOutside = (
  isOpen: boolean,
  onRequestClose: () => void,
  modalRef: React.RefObject<HTMLDivElement>,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      if (
        modalRef.current &&
        !modalRef.current.contains(target) &&
        !(
          target instanceof HTMLInputElement ||
          target instanceof HTMLTextAreaElement ||
          target instanceof HTMLSelectElement
        )
      ) {
        onRequestClose();
      }
    };

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onRequestClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleEscapeKey);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [isOpen, onRequestClose, modalRef]);
};

export default useClickOutside;
