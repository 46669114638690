import React from "react";

/**
 * TitleWithDivider - A functional component that displays a title with an optional divider.
 *
 * This component displays a title text passed as a prop. If the `useDivider` prop is set to `true`,
 * it adds a divider line below the title.
 *
 * @param {Object} props - The props object.
 * @param {string} props.titleText - The text to be displayed as the title.
 * @param {boolean} props.useDivider - A flag indicating whether to show a divider below the title.
 * @returns {JSX.Element} - A JSX element displaying the title with an optional divider.
 *
 * Usage:
 * <TitleWithDivider titleText="Section Title" useDivider={true} />
 */
export const TitleWithDivider = ({ titleText, useDivider }) => (
  <div>
    <div className="mayo-title-text">{titleText}</div>
    {useDivider === true ? <div className="divider mt-5"></div> : ""}
  </div>
);
