import { USER_TYPE, USER_TYPE_STRING } from "../../constants/Enums";
import { ADMINUSER } from "../../types/adminUser";
import { APP } from "../../types/app";
import { BROADCASTGROUP } from "../../types/broadcastGroup";
import { CONTRACT } from "../../types/contract";
import { NOTIFICATION } from "../../types/notification";
import { SKILLTAG } from "../../types/skillTag";
import { TENANT } from "../../types/tenant";
import { TENANTDEPARTMENT } from "../../types/tenantDepartment";
import { USER } from "../../types/user";
import { USERAPP } from "../../types/userApp";
import { USERROLE } from "../../types/userRole";
import { USERTENANT } from "../../types/usertenant";

export const MapUser = (user): USER => ({
  id: user.id,
  uid: user.uid,
  tenant_code: user.tenant_code,
  employee_number: user.employee_number,
  first_name: user.first_name,
  last_name: user.last_name,
  first_furigana_name: user.first_furigana_name,
  last_furigana_name: user.last_furigana_name,
  email: user.email || "",
  profile_image_url: user.profile_image,
  user_type: user.user_type,
  login_id: user.login_id,
  login_password: user.login_password,
  is_display: user.is_display,
  card_image_urls: user.card_images
    ? user.card_images.map((image) => ({
        blobId: image.blob_id,
        url: image.url,
      }))
    : [],
  company_name: user.company_name,
  company_tel: user.company_tel,
  personal_tel: user.personal_tel,
  company_address: user.company_address,
  company_home_page: user.company_home_page,
  date_of_receiving_info: user.date_of_receiving_info,
  position_name:
    user.external_departments?.[0]?.external_name ??
    (user?.user_type === USER_TYPE_STRING.INTERNAL
      ? user.internal_departments?.[0]?.position_name
      : "未登録"),
  department_name:
    user?.user_type === USER_TYPE_STRING.INTERNAL
      ? user.internal_departments?.[0]?.department_name
      : "未登録",
  display_order:
    user.internal_departments?.[0]?.position_code ??
    (user?.user_type === USER_TYPE_STRING.EXTERNAL ? "外部" : "未登録"),

  user_apps: user.available_apps,
  role: user.role,
  created_at: user.created_at,
});

export const MapTenant = (tenant): TENANT => ({
  id: tenant.id,
  code: tenant.code,
  contract_count: tenant.contract_count,
  name: tenant.name,
  name_kana: tenant.name_kana,
  name_english: tenant.name_english || "",
  postal_code: tenant.postal_code,
  address: tenant.address,
  phone_number: tenant.phone_number,
  url: tenant.url,
  local_auth: tenant.local_auth,
  google_auth: tenant.google_auth,
  microsoft_365_auth: tenant.microsoft_365_auth,
  require_passcode_for_phonebook: tenant.require_passcode_for_phonebook,
  disable_call_recording: tenant.disable_call_recording,
  contracts: tenant.contracts,
  shoppers_cloud_url: tenant.shoppers_cloud_url,
  sub_directory: tenant.sub_directory,
  store_id: tenant.store_id,
});

export const MapNotification = (data): NOTIFICATION => ({
  id: data.id,
  title: data.title,
  content: data.content,
  status: data.status,
  start_date: data.start_date,
  end_date: data.end_date,
  created_at: data.created_at,
  updated_at: data.updated_at,
});

export const MapUserTenant = (response): USERTENANT => {
  const userTenantDepartmentData: USERTENANT = {
    id: response.id,
    tenant_department_id: response.department_id,
    department_name: response.department_name,
    position_name: response.position_name,
  };

  return userTenantDepartmentData;
};

export const MapTenantDepartment = (data): TENANTDEPARTMENT => ({
  id: data.id,
  tenant_id: data.tenant_id,
  name: data.name,
  parent: data.parent ? data.parent.id : null,
  name_kana: data.name_kana,
  code: data.code,
  display_order: data.display_order,
  department_level: data.department_level,
});

export const MapBroadcastGroup = (response): BROADCASTGROUP => {
  const broadcastGroupData: BROADCASTGROUP = {
    id: response.id,
    name: response.name,
  };
  return broadcastGroupData;
};

export const MapRoleData = (response) => {
  const roleData = {
    id: response.role,
  };

  return roleData;
};

export const MapUserRoleData = (response): USERROLE => {
  const userRoleData: USERROLE = {
    user_id: response.user_id,
    role_id: response.role_id,
    uid: response.uid,
    created_at: response.created_at,
    updated_at: response.updated_at,
  };
  return userRoleData;
};

export const MapAdminUser = (response): ADMINUSER => {
  const adminUserData: ADMINUSER = {
    tenant_id: response.data.id,
    name: response.data.name,
    name_kana: response.data.name_kana,
    login_id: response.data.login_id,
    login_password: response.data.login_password,
    email: response.data.email,
  };
  return adminUserData;
};

export const MapUserApp = (app): USERAPP => ({
  id: app.id,
  user_id: app.user_id,
  app_id: app.app_id,
  app_name: app.app_name,
  app_description: app.app_description,
});

export const MapApp = (app): APP => ({
  id: app.id,
  name: app.name,
  force_update: app.force_update,
  current_version: app.current_version,
  description: app.description,
});

export const MapUserSkillTagData = (response): SKILLTAG => {
  const userAppData = {
    id: response.id,
    name: response.name,
    description: response.description,
  };

  return userAppData;
};

export const MapContractData = (contract): CONTRACT => ({
  id: contract.id,
  app_name: contract.app_name,
  app_id: contract.app_id,
  tenant_id: contract.tenant_id,
  status: contract.status,
  unit_price: contract.unit_price || 0,
  current_users: contract.current_users || 0,
  contract_count: contract.contract_count || 0,
  start_date: contract.start_date,
  end_date: contract.end_date,
});

export const MapContact = (contact, position_name = ""): USER => ({
  id: contact.id,
  uid: contact.uid,
  tenant_code: contact.tenant_id,
  user_type: contact.user_type,
  employee_number: contact.employee_number,
  name:
    contact.first_name && contact.last_name
      ? `${contact.last_name} ${contact.first_name}`
      : "",
  first_name: contact.first_name,
  last_name: contact.last_name,
  furigana_name:
    contact.first_furigana_name && contact.last_furigana_name
      ? `${contact.last_furigana_name} ${contact.first_furigana_name}`
      : "",
  first_furigana_name: contact.first_furigana_name,
  last_furigana_name: contact.last_furigana_name,
  email: contact.email,
  card_image_urls: contact.card_images
    ? contact.card_images.map((image) => ({
        blobId: image.blob_id,
        url: image.url,
      }))
    : [],
  company_name: contact.company_name,
  company_tel: contact.company_tel,
  personal_tel: contact.personal_tel,
  company_home_page: contact.company_home_page,
  position_name: position_name,
  created_at: contact.created_at,
});
