export enum CONTRACT_STATUS {
  ACTIVE = "active",
  INACTIVE = "no_contract",
  EXPIRED = "expired",
}

export enum USER_TYPE {
  INTERNAL = 1,
  EXTERNAL = 2,
}

export enum USER_TYPE_STRING {
  INTERNAL = "internal",
  EXTERNAL = "external",
}

export enum METHOD_TYPE {
  DELETE = "delete",
  CREATE = "create",
  UPDATE = "update",
}

export enum LOGIN_STATES {
  START = "start",
  LOGGING_IN = "logging_in",
  LOGGED_IN = "logged_in",
  LOGGING_OUT = "logging_out",
}
