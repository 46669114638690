import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./UserSlice";
import authReducer from "./AuthSlice";
import apiReducer from "./ApiSlice";
import companyReducer from "./CompanySlice";
import appReducer from "./AppSlice";
import systemReducer from "./SystemSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    api: apiReducer,
    company: companyReducer,
    apps: appReducer,
    sys: systemReducer,
  },
});
