import { XCircleIcon } from "@heroicons/react/16/solid";
import React, { useRef } from "react";
import useClickOutside from "./ClickOutsideHandler";
import useDrag from "./DragHandler";

interface WarningModalProps {
  title: string;
  body: string;
  confirmText: string;
  cancelText?: string;
  confirmButtonColor?: string;
  isOpen: boolean;
  onConfirm?: () => void;
  onRequestClose: () => void;
}

/**
 * WarningModal - A modal component for displaying a warning message with confirmation and cancellation options.
 *
 * This component renders a modal dialog with a warning message and two buttons: one for confirming the action
 * and another for cancelling it. The modal includes a customizable title, body message, and button texts.
 * The confirm button color can also be customized. It uses drag-and-drop functionality for moving the modal
 * and click outside detection to close the modal when clicking outside.
 *
 * @param {Object} props - The props object.
 * @param {string} props.title - The title of the warning modal.
 * @param {string} props.body - The body message of the warning modal.
 * @param {string} props.confirmText - The text displayed on the confirm button.
 * @param {string} [props.cancelText="キャンセル"] - The text displayed on the cancel button (default is "キャンセル").
 * @param {string} [props.confirmButtonColor="bg-red-500"] - The background color class for the confirm button (default is "bg-red-500").
 * @param {boolean} props.isOpen - A flag indicating whether the modal is open.
 * @param {function} props.onConfirm - A callback function to be called when the confirm button is clicked.
 * @param {function} props.onRequestClose - A callback function to be called when the modal needs to be closed.
 * @returns {JSX.Element | null} - A JSX element representing the warning modal if `isOpen` is true, or null if the modal is closed.
 *
 * Usage:
 * <WarningModal
 *   title="削除確認"
 *   body="この操作は取り消せません。続行しますか？"
 *   confirmText="削除"
 *   isOpen={isModalOpen}
 *   onConfirm={handleDelete}
 *   onRequestClose={closeModalHandler}
 * />
 */

const WarningModal: React.FC<WarningModalProps> = ({
  title,
  body,
  confirmText,
  cancelText = "キャンセル",
  isOpen,
  confirmButtonColor = "bg-red-500",
  onConfirm,
  onRequestClose,
}) => {
  const modalRef: React.RefObject<HTMLDivElement> =
    useRef<HTMLDivElement>(null);

  const { position, handleMouseDown } = useDrag(modalRef, isOpen);
  useClickOutside(isOpen, onRequestClose, modalRef);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center backdrop-brightness-50 z-[1000] z-[1000]">
      <div
        ref={modalRef}
        onMouseDown={handleMouseDown}
        style={{
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
        className="bg-white border border-text-field-border rounded-lg w-[90vw] sm:w-[80vw] md:w-[60vw] lg:w-[40vw] xl:w-[40vw] max-w-[500px] max-h-[90vh] overflow-y-auto"
      >
        <div className="mayo-card-header p-4 flex justify-between items-center">
          <h2 className="text-lg font-semibold">{title}</h2>
          <button onClick={onRequestClose} className="text-xl hover:opacity-50">
            <svg
              className="ml-2 fill-body hover:fill-primary"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <XCircleIcon />
            </svg>
          </button>
        </div>

        <p className="text-black text-lg mb-8 ml-4 mt-4 whitespace-pre-wrap break-words">
          {body}
        </p>
        <div className="flex justify-end gap-2.5 mb-5 mr-4">
          <button
            onClick={onRequestClose}
            className="button-small-white border-text-field-border"
          >
            {cancelText === "" ? "キャンセル" : cancelText}
          </button>
          {confirmText !== "" && (
            <button
              onClick={onConfirm}
              className={`${confirmButtonColor} hover:opacity-50 button-modal`}
            >
              {confirmText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
