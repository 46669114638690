import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { error_message } from "../../constants/Errors";

/**
 * ContractRoute - A higher-order component that restricts access to routes based on available apps.
 *
 * This component checks if there are available apps stored in the Redux state.
 * If no apps are available, it renders an "Access Denied" message.
 * If apps are available, it renders the provided component.
 *
 * @param {Object} props - The props object.
 * @param {React.ComponentType} props.element - The component to be rendered if apps are available.
 * @param {...Object} rest - The remaining props that are passed to the component.
 * @returns {JSX.Element} - The restricted component if apps are available, otherwise an access denied message.
 *
 * Usage:
 * <ContractRoute element={YourComponent} someProp="someValue" />
 */
const ContractRoute = ({ element: Component, appName, ...rest }) => {
  const [hasLicense, setHasLicense] = useState(false);
  const availableApps = useSelector((state) => state.apps.availableApps);

  useEffect(() => {
    if (availableApps && Array.isArray(availableApps)) {
      if (availableApps.includes(appName)) {
        setHasLicense(true);
      } else {
        setHasLicense(false);
      }
    }
  }, [availableApps, appName]);

  if (!hasLicense) {
    return (
      <div className="flex flex-col">
        <div className="min-h-screen flex flex-col items-center justify-center">
          <h1 className="text-4xl font-bold">
            {error_message.generic.access_denied}
          </h1>
          <p className="text-lg mt-4">
            {error_message.generic.app_not_available(appName)}
          </p>
        </div>
      </div>
    );
  }

  return <Component {...rest} />;
};

export default ContractRoute;
