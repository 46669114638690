import { createSlice } from "@reduxjs/toolkit";

export const companySlice = createSlice({
  name: "company",
  initialState: {
    companyId: null,
  },
  reducers: {
    setCompanyIdState: (state, action) => {
      state.companyId = action.payload;
    },
    resetUser: (state) => {
      state.companyId = null;
    },
  },
});

export const { setCompanyIdState, resetUser } = companySlice.actions;

export default companySlice.reducer;
