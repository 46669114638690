import React from "react";
import { TitleWithDivider } from "../../common/TitleWithDivider.tsx";
import { useParams } from "react-router-dom";
import ViewTenantForm from "../../components/Forms/ViewTenantForm.tsx";

const ViewTenantPage: React.FC = () => {
  const { tenantCode } = useParams<{ tenantCode: string }>();

  return (
    <>
      <div>
        <TitleWithDivider titleText="企業情報設定" useDivider={true} />
        <ViewTenantForm tenantCode={tenantCode}></ViewTenantForm>
      </div>
    </>
  );
};

export default ViewTenantPage;
