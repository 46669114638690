import React, { ChangeEvent, useEffect, useState } from "react";
import { PlusIcon } from "@heroicons/react/16/solid";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import DeleteCardModal from "../Modals/WarningModal";
import { deleteCardImage, uploadCardImage } from "../../services/API/Requests";
import { error_message } from "../../constants/Errors";
import { toast } from "react-toastify";
import messages from "../../constants/Messages";

/**
 * BusinessCardTable - A component for displaying and managing business card images.
 *
 * This component displays a table of business card images associated with a user.
 * It allows for uploading new business cards, removing existing ones, and displaying
 * the status of each upload. It also provides a modal confirmation for deleting a card.
 *
 * @param {Object} props - The props object.
 * @param {number} props.userId - The ID of the user to associate the business cards with.
 * @param {string[]} props.cardData - An array of URLs representing the initial business card images.
 * @param {function} props.setData - A function to update the card data state in the parent component.
 * @param {boolean} props.isCreate - A flag indicating whether the component is in create mode.
 *
 * @returns {JSX.Element} - A JSX element representing the business card table.
 *
 * Usage:
 * <BusinessCardTable
 *   userId={userId}
 *   cardData={cardData}
 *   setData={setData}
 *   isCreate={isCreate}
 * />
 */

const BusinessCardTable = ({
  userId,
  cardData,
  setData,
  isCreate,
  disabled,
}) => {
  // delete warning modal variables
  const warnModalTitle = "名刺を削除";
  const warnModalBody = "名刺を削除します。よろしいですか？";
  const warnModalConfirm = "削除する";

  const [id, setId] = useState<number>(userId);
  const [file, setFile] = useState<File[]>([]);
  const [businessCards, setBusinessCards] = useState<any>(cardData);
  const [uploadStatus, setUploadStatus] = useState<string[]>([]);
  const [selectedCardIndex, setSelectedCardIndex] = useState<null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);

  const [isDisabled, setIsDisabled] = useState<boolean>(disabled);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const errorToast = (response) => toast.error(`${response}`);
  const successToast = (response) => toast.success(`${response}`);

  useEffect(() => {
    setId(userId);
    setUploadStatus(Array(cardData.length).fill("uploaded"));
    setIsDisabled(disabled);
    setBusinessCards(cardData);
  }, [userId, cardData]);

  const handleFileAddition = (event: ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files!);
    setFile((prevFiles) => [...newFiles, ...prevFiles]);

    const newFileURLs = newFiles.map((file) => URL.createObjectURL(file));
    setPreviewUrls((prevUrls) => [...newFileURLs, ...prevUrls]);

    const updatedCards = [
      ...newFileURLs.map((url) => ({ url, blobId: null })),
      ...businessCards,
    ];
    setBusinessCards(updatedCards);

    setData(newFiles);

    const newStatuses = newFiles.map(() => "not_uploaded");
    setUploadStatus((prevStatuses) => [...newStatuses, ...prevStatuses]);
  };

  const handleDelete = (index) => {
    const isPreview = businessCards[index]?.url.includes("blob");

    if (isPreview) {
      const updatedCards = businessCards.filter((_, i) => i !== index);
      const updatedPreviews = previewUrls.filter((_, i) => i !== index);
      setBusinessCards(updatedCards);
      setPreviewUrls(updatedPreviews);
      setData(updatedCards);

      const updatedStatus = uploadStatus.filter((_, i) => i !== index);
      setUploadStatus(updatedStatus);
    } else {
      deleteCardImage(userId, index, setLoading, setError)
        .then((response) => {
          const updatedCards = businessCards.filter((_, i) => i !== index);
          setBusinessCards(updatedCards);
          setData(updatedCards);

          const updatedStatus = uploadStatus.filter((_, i) => i !== index);
          setUploadStatus(updatedStatus);
        })
        .catch((error) => {
          console.error(error_message.image.delete, error);
        });
    }
  };

  const handleCardUpload = async (index: number) => {
    const fileToUpload: File = file[index];
    let fileUploaded = "";
    try {
      const response = await uploadCardImage(id, fileToUpload);

      if (response.status !== 200) {
        errorToast(response.translatedMessage);
      } else {
        successToast(messages.image.upload_success);
        fileUploaded = "uploaded";
      }

      const updatedStatus = [...uploadStatus];
      updatedStatus[index] = fileUploaded;
      setUploadStatus(updatedStatus);
    } catch (error) {
      console.error(error_message.image.upload, error);
    }
  };

  const handleOpenModal = (index) => {
    setSelectedCardIndex(index);
    setDeleteModalOpen(true);
  };

  const handleCancelDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleConfirm = () => {
    setDeleteModalOpen(false);
    handleDelete(selectedCardIndex);
  };

  return (
    <div className="mt-10 flex items-center gap-10 overflow-x-auto">
      {businessCards.map((link, index) => (
        <div key={index} className="flex flex-col items-center min-w-100">
          <div className="flex flex-col items-start">
            {index === 0 ? (
              <div className="mb-2 button-small-status">最新</div>
            ) : (
              <div className="mb-15"></div>
            )}
            <img
              src={link.url || link}
              alt={`card-${index}`}
              className="w-100 h-auto max-w-100"
            />
          </div>
          <div className="flex gap-2.5 mt-5">
            {uploadStatus[index] === "uploaded" || isCreate ? (
              <div className="l-2 text-xl text-mayo-light-gray">
                {isDisabled ? "" : "取込済み"}
              </div>
            ) : !isDisabled ? (
              <div className="flex items-center">
                <div className="flex items-center hover:opacity-50">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <PlusIcon className="fill-mayo-link-text"></PlusIcon>
                  </svg>
                  <div
                    className="text-xl text-mayo-link-text hover:opacity-50"
                    onClick={(e) => handleCardUpload(index)}
                  >
                    取込を行う
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {!isDisabled ? (
              <div className="ml-10 flex items-center hover:opacity-50">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 13 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 3V2.6C8.5 2.03995 8.5 1.75992 8.39101 1.54601C8.29513 1.35785 8.14215 1.20487 7.95399 1.10899C7.74008 1 7.46005 1 6.9 1H6.1C5.53995 1 5.25992 1 5.04601 1.10899C4.85785 1.20487 4.70487 1.35785 4.60899 1.54601C4.5 1.75992 4.5 2.03995 4.5 2.6V3M5.5 5.75V8.25M7.5 5.75V8.25M2 3H11M10 3V8.6C10 9.44008 10 9.86012 9.83651 10.181C9.6927 10.4632 9.46323 10.6927 9.18099 10.8365C8.86012 11 8.44008 11 7.6 11H5.4C4.55992 11 4.13988 11 3.81901 10.8365C3.53677 10.6927 3.3073 10.4632 3.16349 10.181C3 9.86012 3 9.44008 3 8.6V3"
                    stroke="#0695FF"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div
                  className="text-xl flex items-center text-mayo-link-text hover:opacity-60"
                  onClick={() => handleOpenModal(index)}
                >
                  削除
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ))}
      {!isDisabled && (
        <div className="flex items-center mt-10 ml-20 p-20">
          <input
            type="file"
            onChange={handleFileAddition}
            accept=".png,.jpg,.jpeg"
            className="hidden"
            id="fileInput"
          />
          <label
            htmlFor="fileInput"
            className="flex flex-col items-center p-5 text-black hover:opacity-50 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="none"
              strokeWidth="2"
              className="feather feather-plus h-12 w-12"
            >
              <PlusCircleIcon className="stroke-mayo-link-text"></PlusCircleIcon>
            </svg>
            <div className="min-w-40 mt-5 font-bold text-xl">
              新規アップロード
            </div>
          </label>
        </div>
      )}

      {isDisabled && cardData.length <= 0 && (
        <div>{messages.user.no_cards}</div>
      )}
      <DeleteCardModal
        isOpen={deleteModalOpen}
        onRequestClose={handleCancelDeleteModal}
        onConfirm={handleConfirm}
        title={warnModalTitle}
        body={warnModalBody}
        confirmText={warnModalConfirm}
        cancelText={""}
      />
    </div>
  );
};

export default BusinessCardTable;
