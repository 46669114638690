import React, { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CsvUploader } from "../../common/UploadCsvTemplate";
import {
  getUploadStatus,
  postUserDataArray,
  postUserDataArrayCSV,
} from "../../services/API/Requests";
import { TranslateErrorMessage } from "../../services/ErrorMessages";
import LoadingIcon from "../../common/LoadingIcon";
import { USER } from "../../types/user";
import { GetCurrentDate } from "../../services/Utilities";
import { userHeaderMapping } from "../../services/TranslateCsvHeaders";
import { error_message } from "../../constants/Errors";
import messages from "../../constants/Messages";
import { USER_TYPE } from "../../constants/Enums";
import { USER_PATHS } from "../../constants/NavigationPaths";
import { UserIcon } from "@heroicons/react/24/outline";

/**
 * UserCSVUploadPage - A component for uploading user data via a CSV file.
 *
 * This component provides a CSV uploader for adding multiple user records to the system at once.
 * It allows users to download a CSV template, select and upload a CSV file, and view a preview of the uploaded data.
 *
 * State:
 * - `loading` (boolean): Indicates whether the component is in a loading state while processing the CSV data or making requests.
 * - `error` (string | null): Stores error messages encountered during the CSV upload process or API interactions.
 * - `csvData` (array): Stores the parsed data from the uploaded CSV file.
 *
 * Functions:
 * - `validateHeaders`: Verifies that the uploaded CSV file contains all the required headers.
 * - `handleCsvUpload`: Validates and uploads the user data from the CSV file to the backend API. Displays success or error messages based on the response.
 * - `onCancel`: Navigates back to the user list page without saving any changes.
 *
 * API Interactions:
 * - `postUserData`: Sends the parsed user data to the backend API for creating user records.
 *
 * Components:
 * - `CsvUploader`: A reusable component for handling CSV file uploads and displaying a preview of the data.
 *
 * Toast Notifications:
 * - `noCsvImportError`: Displays an error message if no CSV data is available for upload.
 * - `uploadCsvDataToast`: Displays a success message with the number of user records uploaded successfully.
 * - `failCsvDataToast`: Displays an error message if there is an issue uploading the CSV data.
 *
 * Usage:
 * This component is used for bulk uploading user data through a CSV file. It is typically accessible from an admin or management page where user management is performed.
 *
 * Example Usage:
 * <UserCSVUploadPage />
 *
 * Note:
 * - The `validateHeaders` function ensures that the uploaded CSV file has all the required headers before processing the data.
 * - The `handleCsvUpload` function maps the CSV data to the user object structure expected by the API and sends it to the backend.
 * - The component includes error handling to provide feedback if the CSV data is not in the expected format or if there are issues during the upload process.
 * - The user data includes additional information such as roles, tenant information, and assigned applications, which are also handled during the upload.
 */

const UserCSVUploadPage = () => {
  const navigate: NavigateFunction = useNavigate();

  const [csvData, setCsvData] = useState<[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [failedUsers, setFailedUsers] = useState<[]>();

  const currentDate = GetCurrentDate();

  const sampleData = [
    {
      id: 0,
      first_name: "花子",
      last_name: "佐藤",
      first_kana_name: "HANAKO",
      last_kana_name: "SATO ",
      first_furigana_name: "はなこ",
      last_furigana_name: "さとう",
      employee_number: "123456",
      email: "hanako.sato@example.com",
      is_display: true,
      personal_tel: "09023456789",
      login_id: "hanako",
      login_password: "securePassword1!",
      tenant_department_id: "A1000",
      position_name: "開発者",
      role: 1,
      apps: ["YOBELY", "KAKELY"],
      delete: 0,
    },
  ];

  const expectedHeaders: string[] = [
    "id",
    "漢字（名）",
    "漢字（姓）",
    "かな（名）",
    "かな（姓）",
    "権限　（１：一般者、　２：管理者）",
    "部署コード（任意）",
    "役職（任意）",
    "メールアドレス（任意）",
    "アカウント状態（有効：TRUE、無効：FALSE）",
    "電話番号",
    "ログインID",
    "ログインパスワード",
    "利用アプリ",
    "削除フラグ",
  ];

  const noCsvImportError = () => toast.error(error_message.csv.no_data);
  const uploadCsvDataToast = (message) => toast.success(message);
  const failCsvDataToast = (error) => toast.error(`${error}`);

  const [finalResult, setFinalResult] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [procesedCount, setProcesedCount] = useState(0);

  const handleCsvUpload = async () => {
    setFinalResult(null);
    setLoading(true);
    if (csvData.length === 0) {
      noCsvImportError();
      return;
    }

    const userDataArray = csvData.map((userInfo: USER, index: number) => {
      const user: USER = {
        id: userInfo.id,
        employee_number: userInfo.employee_number,
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        kana_first_name: userInfo.kana_first_name,
        kana_last_name: userInfo.kana_last_name,
        first_furigana_name: userInfo.first_furigana_name,
        last_furigana_name: userInfo.last_furigana_name,
        user_type: USER_TYPE.INTERNAL,
        personal_tel: userInfo.personal_tel,
        email: userInfo.email,
        is_display: Boolean(userInfo.is_display),
        login_id: userInfo.login_id,
        login_password: userInfo.login_password,
        display_order: userInfo.display_order,
        position_name: userInfo.position_name,
        delete: userInfo.delete,
      };

      const roleData = {
        id: userInfo.role || import.meta.env.VITE_ROLE_ID,
      };

      const userTenantData = {
        tenant_department_id: userInfo.tenant_department_id || 0,
      };

      const appsArray = userInfo.apps?.split(",");

      let userApps = [];
      if (appsArray && appsArray[0] !== "") {
        userApps = appsArray.map((appName) => {
          return { name: appName };
        });
      }

      return { userInfo: user, userTenantData, roleData, userApps, index };
    });

    const postUserInformation = async (userDataArray) => {
      let response;

      setCsvData((prevCsvData) =>
        prevCsvData.map((user, newIndex) => ({ ...user, index: newIndex })),
      );

      try {
        response = await postUserDataArrayCSV(
          userDataArray,
          setLoading,
          setError,
        );
        if (
          response.responseMessage.status >= 300 ||
          response.responseMessage.status == null
        ) {
          const errorMessage = TranslateErrorMessage(response.responseMessage);
          setFailedUsers(response.failed_users);
          console.error(errorMessage);
          setError(errorMessage);
          failCsvDataToast(response.failed_users.error);
          return;
        } else if (response.responseMessage.status == 202) {
          response = await pollForStatus(
            response.request_id,
            userDataArray.length,
          );
        } else {
          uploadCsvDataToast(
            messages.user.uploaded(
              response.created_users.length || 1,
              response.failed_users.length || 0,
            ),
          );
        }
      } catch (error) {
        if (userDataArray.length == 1 && response.failed_users) {
          failCsvDataToast(response.failed_users.error);
          return;
        }

        if (response && response.failed_users) {
          setFailedUsers(response.failed_users);
        }
        const errorMessage = TranslateErrorMessage(response.responseMessage);
        console.error(errorMessage);
        setError(errorMessage);

        const toastFunction =
          response.created_users.length <= 0
            ? uploadCsvDataToast
            : failCsvDataToast;

        const createdCount =
          response.created_users.length ||
          (response.created_users.length ? 1 : 0);
        const failedCount =
          response.failed_users.length ||
          (response.created_users.length ? 0 : 1);

        toastFunction(messages.user.uploaded(createdCount, failedCount));
      }
    };
    await postUserInformation(userDataArray);
  };

  const onCancel = () => {
    navigate(USER_PATHS.main);
  };

  const pollForStatus = async (requestId, totalUsers) => {
    const POLL_INTERVAL = 2500;
    const TIMEOUT = 60000;
    const startTime = Date.now();

    setIsProcessing(true);

    const poll = async () => {
      try {
        const statusResponse = await getUploadStatus(requestId, totalUsers);

        const { message, processed_count, total_users } = statusResponse.data;

        setProcesedCount(processed_count);

        if (message !== "still processing" && processed_count === total_users) {
          setIsProcessing(false);
          processResult(statusResponse.data);
          return statusResponse.data;
        }

        if (Date.now() - startTime > TIMEOUT) {
          setIsProcessing(false);
          console.error("timeout: status polling stopped.");
          return;
        }

        setTimeout(poll, POLL_INTERVAL);
      } catch (error) {
        console.error("error during status polling:", error);
        setIsProcessing(false);
      }
    };

    poll();
  };

  const processResult = (finalResult) => {
    if (finalResult.failed_users.length > 0) {
      failCsvDataToast(
        messages.user.uploaded(
          finalResult.created_users.length,
          finalResult.failed_users.length,
        ),
      );
    } else {
      uploadCsvDataToast(
        messages.user.uploaded(
          finalResult.created_users.length,
          finalResult.failed_users.length,
        ),
      );
    }

    finalResult.failed_users?.forEach((user) => {
      const translatedMessage = TranslateErrorMessage(user.error);
      user.error = translatedMessage;
    });

    setFailedUsers(finalResult.failed_users);
  };

  return (
    <div className="relative">
      {(loading || isProcessing) && (
        <div className="absolute inset-0 flex items-center justify-center z-50">
          <div className="bg-white bg-opacity-80 p-5 rounded-lg shadow-lg flex items-center justify-center">
            <LoadingIcon
              bodyText={
                isProcessing
                  ? `${procesedCount || 0} / ${csvData.length || 0}`
                  : ""
              }
            />
          </div>
        </div>
      )}
      <CsvUploader
        titleText="ユーザCSVアップロード"
        expectedHeaders={expectedHeaders}
        uploadHandler={handleCsvUpload}
        setCsvData={setCsvData}
        csvData={csvData}
        onCancel={onCancel}
        sampleData={sampleData}
        failedUsers={failedUsers}
        templateName={`ユーザ用CSVサンプル_${currentDate}`}
        headerMapping={userHeaderMapping}
        fromUserPage={true}
      />
    </div>
  );
};

export default UserCSVUploadPage;
