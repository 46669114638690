// get and return the current date in japanese format (YYY-MM-dd)
export const GetCurrentDate = () => {
  const date = new Date();

  const options = {
    timeZone: "Asia/Tokyo",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  return new Intl.DateTimeFormat("ja-JP", options)
    .format(date)
    .replace(/\//g, "");
};

// convert all double width and half width spaces to single width
export const NormalizeSpaces = (input) => {
  return input.replace(/[\u0020\u3000]+/g, " ");
};

// returns the password policy
export const GetPasswordPolicy = () => {
  return "英字大文字、小文字、数字、記号を全て含み、8文字以上";
};
