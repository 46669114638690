import { useState, useEffect } from "react";

const useDrag = (
  modalRef: React.RefObject<HTMLDivElement>,
  isOpen: boolean,
) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [dragStart, setDragStart] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (isDragging) {
        const dx = event.clientX - dragStart.x;
        const dy = event.clientY - dragStart.y;

        const modal = modalRef.current;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        if (modal) {
          const modalRect = modal.getBoundingClientRect();
          const newX = Math.max(
            -(modalRect.width * 2),
            Math.min(position.x + dx, windowWidth - modalRect.width),
          );
          const newY = Math.max(
            -(modalRect.height * 2),
            Math.min(position.y + dy, windowHeight - modalRect.height),
          );

          setPosition({ x: newX, y: newY });
          setDragStart({ x: event.clientX, y: event.clientY });
        }
      }
    };

    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, dragStart, position, modalRef]);

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;

    // Prevent dragging for specific elements
    if (target.isContentEditable || target.tagName === "SELECT") {
      return;
    }

    if (modalRef.current && modalRef.current.contains(event.target as Node)) {
      setIsDragging(true);
      setDragStart({ x: event.clientX, y: event.clientY });
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setPosition({ x: 0, y: 0 });
    }
  }, [isOpen]);

  return { position, handleMouseDown, handleMouseUp };
};

export default useDrag;
