import React, { useEffect, useState } from "react";
import { NOTIFICATION } from "../../../types/notification";
import { ConfirmCancelButtons } from "../../Buttons/ConfirmCancelButtons";
import { postNotificationData } from "../../../services/API/Requests";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BlankNotification } from "../../../templates/BlankNotificationData";
import { error_message } from "../../../constants/Errors";

/**
 * CreateNotificationCard - A component for creating notifications.
 *
 * This component displays the form to create a notification. It uses a generic BlankNotification object to populate empty fields.
 * The user is then able to add information relating to the notification.
 *
 *
 * Usage:
 * <CreateNotificationCard/>
 */

const CreateNotificationCard = () => {
  const [notificationData, setNotificationData] = useState(BlankNotification);
  const [editableTo, setEditableTo] = useState("");
  const [editableFrom, setEditableFrom] = useState("");
  const [isEditing, setIsEditing] = useState(true);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [dateError, setDateError] = useState("");

  const navigate = useNavigate();

  const errorToast = (response) => toast.error(`${response}`);
  const successToast = (response) => toast.success(`${response}`);

  useEffect(() => {
    if (notificationData?.start_date) {
      const fromDate = new Date(notificationData?.start_date);
      const formattedFrom = fromDate.toISOString().split("T")[0];
      setEditableFrom(formattedFrom);
    }

    if (notificationData?.end_date) {
      const toDate = new Date(notificationData?.end_date);
      const formattedTo = toDate.toISOString().split("T")[0];
      setEditableTo(formattedTo);
    }
  }, [notificationData?.start_date, notificationData?.end_date]);

  const updateNotificationInformation = (
    key: keyof NOTIFICATION,
    value: string | boolean,
  ) => {
    setNotificationData((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          [key]: value,
        };
      }
      return prevState;
    });
  };

  const validateDates = () => {
    const startDate = new Date(notificationData?.start_date);
    const endDate = new Date(notificationData?.end_date);

    if (startDate > endDate) {
      setDateError(error_message.notification.start_data);
      return false;
    } else {
      setDateError("");
      return true;
    }
  };

  const saveNotificationInformation = (event) => {
    event.preventDefault();

    if (validateDates()) {
      postNotificationData(notificationData, setLoading, setError)
        .then((response) => {
          if (response.status == 200) {
            successToast(response.message);
            setTimeout(() => {
              navigate("/notifications");
            }, 3000);
          } else {
            errorToast(response);
          }
        })
        .catch((error) => {
          setError(error);
          errorToast(error);
        });
    }
  };

  const handleCancel = () => {
    navigate("/home");
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          saveNotificationInformation(e);
        }}
      >
        <div className="rounded-xl border border-text-field-border bg-white py-6 px-3.5">
          <div className="mt-2 flex items-end justify-between">
            <div className="w-full">
              <div>
                <h4 className={"button-small-status w-35 mb-10"}>
                  {notificationData?.status === true ? "配信中" : "停止"}
                </h4>
              </div>

              {isEditing ? (
                <>
                  <div>
                    <div className="text-font-label-blue font-bold text-xl mb-2">
                      タイトル
                    </div>
                    <input
                      type="text"
                      value={notificationData?.title}
                      required
                      onChange={(e) =>
                        updateNotificationInformation("title", e.target.value)
                      }
                      className="text-title-md font-bold text-black w-1/2 border border-text-field-border rounded-xl p-2"
                    />
                  </div>

                  <div className="divider mt-5"></div>

                  <div className="w-full mb-4">
                    <div className="text-font-label-blue font-bold text-xl mb-2">
                      内容
                    </div>
                    <textarea
                      value={notificationData?.content}
                      required
                      onChange={(e) =>
                        updateNotificationInformation("content", e.target.value)
                      }
                      className="text-title-md text-black w-3/4 min-h-150 border border-text-field-border rounded-xl p-2"
                    />
                  </div>

                  <div className="divider mt-5"></div>

                  <div className="text-font-label-blue font-bold text-xl mb-2">
                    配信期間
                  </div>

                  <div className="flex items-center">
                    <div className="mr-2">
                      <div className="text-md font-medium">〜から</div>
                      <input
                        type="date"
                        value={notificationData?.start_date}
                        required
                        onChange={(e) =>
                          updateNotificationInformation(
                            "start_date",
                            e.target.value,
                          )
                        }
                        className="text-title-md font-bold text-black mb-2 w-auto border border-text-field-border rounded-xl p-2"
                      />
                    </div>

                    <div className="mx-2 text-2xl font-bold">〜</div>

                    <div className="ml-2">
                      <div className="text-md font-medium">〜まで</div>
                      <input
                        type="date"
                        value={notificationData?.end_date}
                        required
                        onChange={(e) =>
                          updateNotificationInformation(
                            "end_date",
                            e.target.value,
                          )
                        }
                        className="text-title-md font-bold text-black mb-2 w-auto border border-text-field-border rounded-xl p-2"
                      />
                    </div>
                  </div>

                  {dateError && (
                    <div className="text-red-500 font-bold mt-2">
                      {dateError}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div>
                    <h2 className="text-md font-medium">タイトル</h2>
                    <p className="text-title-md text-black mb-5">
                      {notificationData?.title}
                    </p>
                  </div>

                  <div>
                    <h2 className="text-md font-medium">作成日</h2>
                    <p className="text-title-md text-black mb-5">
                      {notificationData?.created_at.split("T")[0]}
                    </p>
                  </div>

                  <div className="w-full mb-4">
                    <h2 className="text-md font-medium">内容</h2>
                    <p className="text-title-md text-black mb-5">
                      {notificationData?.content}
                    </p>
                  </div>

                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3 text-left text-xl font-medium text-gray300 uppercase tracking-wider"
                          >
                            配信期間
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        <tr>
                          <td className="px-6 py-4 whitespace-nowrap text-xl font-medium text-gray-900">
                            {editableFrom}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-xl font-large text-gray-900">
                            ~
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-xl text-gray-500">
                            {editableTo}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <ConfirmCancelButtons
          confirmText={"作成"}
          form={true}
          onCancel={handleCancel}
        ></ConfirmCancelButtons>
      </form>
    </>
  );
};

export default CreateNotificationCard;
