import React from "react";

import { TitleWithDivider } from "../../common/TitleWithDivider.tsx";

import UserInformation from "../../components/Forms/UserProfileForm.tsx";

const CreateUserPage: React.FC = () => {
  return (
    <>
      <div>
        <TitleWithDivider titleText="ユーザ新規追加" useDivider={true} />
        <UserInformation id="createUser"></UserInformation>
      </div>
    </>
  );
};

export default CreateUserPage;
