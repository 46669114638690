import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    status: false,
    isAdmin: false,
    isFirstUser: false,
    realm: "",
    token: null,
    loginStatus: "",
    isAdminApplication: false,
  },
  reducers: {
    setAuthState: (state, action) => {
      state.status = action.payload;
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    setIsFirstUser: (state, action) => {
      state.isFirstUser = action.payload;
    },
    setRealmState: (state, action) => {
      state.realm = action.payload;
    },
    setTokenState: (state, action) => {
      state.token = action.payload;
    },
    setLoginStatus: (state, action) => {
      state.loginStatus = action.payload;
    },
    setIsAdminApplication: (state, action) => {
      state.isAdminApplication = action.payload;
    },
    resetIsAdminApplication: (state) => {
      state.isAdminApplication = false;
    },
    resetisAdmin: (state) => {
      state.isAdmin = false;
    },
    resetisFirstUser: (state) => {
      state.isFirstUser = false;
    },
    resetAuthState: (state) => {
      state.status = false;
    },
    resetTokenState: (state) => {
      state.status = false;
    },
  },
});

export const {
  setAuthState,
  resetAuthState,
  setIsAdmin,
  resetisAdmin,
  setTokenState,
  resetTokenState,
  setRealmState,
  setLoginStatus,
  setIsFirstUser,
  resetisFirstUser,
  setIsAdminApplication,
  resetIsAdminApplication,
} = authSlice.actions;

export default authSlice.reducer;
