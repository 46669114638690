import { createSlice } from "@reduxjs/toolkit";

export const systemSlice = createSlice({
  name: "sys",
  initialState: {
    loading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setLoading } = systemSlice.actions;

export default systemSlice.reducer;
