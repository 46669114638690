import { NOTIFICATION } from "../types/notification";

export const BlankNotification: NOTIFICATION = {
  id: "",
  title: "",
  content: "",
  status: true,
  start_date: "",
  end_date: "",
  created_at: "",
};
