import React from "react";

interface ConfirmCancelButtonsProps {
  confirmText: string;
  onConfirm?: () => void;
  cancelText?: string;
  onCancel: () => void;
  form?: boolean;
  disabled?: boolean;
}

/**
 * ConfirmCancelButtons - A reusable component that renders confirm and cancel buttons.
 *
 * This component displays a pair of buttons: a cancel button and a confirm button. It allows for customizable
 * button text and actions via props. If the `form` prop is set to `true`, the confirm button will have a
 * `type="submit"` attribute for use within forms.
 *
 * @param {Object} props - The props object.
 * @param {string} props.confirmText - The text displayed on the confirm button.
 * @param {function} [props.onConfirm] - Optional callback function executed when the confirm button is clicked.
 * @param {string} [props.cancelText] - Optional text displayed on the cancel button. Defaults to "キャンセル" if not provided.
 * @param {function} props.onCancel - Callback function executed when the cancel button is clicked.
 * @param {boolean} [props.form=false] - A flag indicating if the confirm button should act as a submit button (`type="submit"`).
 *                                       Defaults to `false`.
 * @returns {JSX.Element} - A JSX element containing the confirm and cancel buttons with the specified behavior.
 *
 * Usage:
 * <ConfirmCancelButtons
 *   confirmText="Submit"
 *   onConfirm={handleConfirm}
 *   cancelText="Cancel"
 *   onCancel={handleCancel}
 *   form={true}
 * />
 */
export const ConfirmCancelButtons: React.FC<ConfirmCancelButtonsProps> = ({
  confirmText,
  onConfirm,
  cancelText,
  onCancel,
  form = false,
  disabled = false,
}) => {
  return (
    <div className="flex justify-end items-center gap-2.5 mt-10">
      <div className="flex">
        <button
          className="button-cancel"
          onClick={onCancel}
          disabled={disabled}
          type="button"
        >
          {cancelText === undefined ? "キャンセル" : cancelText}
        </button>
        {!form ? (
          <button
            className="button-confirm"
            onClick={onConfirm}
            disabled={disabled}
          >
            {confirmText}
          </button>
        ) : (
          <button className="button-confirm" type="submit" disabled={disabled}>
            {confirmText}
          </button>
        )}
      </div>
    </div>
  );
};
