import utf8Replacements from "../constants/utf8Converter";
import { convertTenantToCsv, convertToCSV } from "./ConvertToCsv";
import { GetCurrentDate } from "./Utilities";
import Encoding from "encoding-japanese";

const handleDownloadCSV = (
  selectedUsers,
  filename,
  headerMapping,
  type = "user",
) => {
  const selectedUserData = selectedUsers;
  let csvContent = "";

  if (type === "tenant") {
    csvContent = convertTenantToCsv(selectedUserData, headerMapping);
  } else {
    csvContent = convertToCSV(selectedUserData, headerMapping);
  }

  const currentDate = GetCurrentDate();

  utf8Replacements.forEach(({ pattern, replacement }) => {
    csvContent = csvContent.replace(pattern, replacement);
  });

  const sjisArray = Encoding.convert(Encoding.stringToCode(csvContent), "SJIS");

  const uint8Array = new Uint8Array(sjisArray);

  const blob = new Blob([uint8Array], {
    type: "text/csv;charset=shift_jis;",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute(
    "download",
    `${selectedUserData.length}${filename}${currentDate}.csv`,
  );
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default handleDownloadCSV;
