import { MagnifyingGlassIcon } from "@heroicons/react/16/solid";
import React, { useState } from "react";
import { error_message } from "../../constants/Errors";

interface SearchBarProps {
  searchBarText: string;
  buttonLeft: string;
  buttonLeftStyle?: string;
  buttonRight: string;
  buttonRightStyle?: string;
  displayRightButton?: boolean;
  disabled?: boolean;
  onSearch?: (searchTerm: string) => void;
  onFilterSearch?: () => void;
  setSearchTerm: (value: string) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  searchBarText,
  buttonLeft,
  buttonLeftStyle = "button-searchbar-search",
  buttonRight,
  buttonRightStyle = "button-searchbar-narrow",
  disabled = false,
  displayRightButton = true,
  onSearch = () => {
    console.error(error_message.generic.no_func_bound);
  },
  onFilterSearch = () => {
    console.error(error_message.generic.no_func_bound);
  },
  setSearchTerm = () => {},
}) => {
  const [hasText, setHasText] = useState(false);

  const handleInput = (input: string) => {
    setSearchTerm(input);
    setHasText(input !== "");
  };

  const runOnSearch = () => {
    onSearch(searchBarText);
  };

  const runOnFilterSearch = () => {
    onFilterSearch();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      runOnSearch();
    }
  };

  return (
    <div className="bg-white p-2 border border-text-field-border rounded-xl">
      <div className="my-5 mx-auto max-w-full">
        <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
          <div className="flex flex-row space-x-4 mb-4 md:mb-0">
            <button
              className={`${buttonLeftStyle} flex-shrink-0`}
              onClick={runOnSearch}
              disabled={disabled}
            >
              {buttonLeft}
            </button>
            {displayRightButton && (
              <button
                className={`${buttonRightStyle} flex-shrink-0`}
                onClick={runOnFilterSearch}
                disabled={disabled}
              >
                {buttonRight}
              </button>
            )}
          </div>
          <div className="relative flex-grow">
            {!hasText && (
              <div className="absolute left-2 top-1/2 transform -translate-y-1/2 items-center">
                <MagnifyingGlassIcon className="w-5 h-5 text-gray-500" />
              </div>
            )}
            <input
              type="text"
              placeholder={searchBarText}
              onChange={(e) => handleInput(e.target.value)}
              onKeyDown={handleKeyDown}
              disabled={disabled}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
