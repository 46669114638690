import React from "react";
import { useSelector } from "react-redux";
import { error_message } from "../../constants/Errors";

/**
 * AdminRoute - A higher-order component that restricts access to routes based on user authentication.
 *
 * This component checks if the user has the required authentication role stored in the Redux state.
 * If the user is not authenticated, it renders an "Access Denied" message.
 * If the user is authenticated, it renders the provided component.
 *
 * @param {Object} props - The props object.
 * @param {React.ComponentType} props.element - The component to be rendered if the user is authenticated.
 * @param {...Object} rest - The remaining props that are passed to the component.
 * @returns {JSX.Element} - The restricted component if authenticated, otherwise an access denied message.
 *
 * Usage:
 * <AdminRoute element={YourComponent} someProp="someValue" />
 */
const AdminRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAdmin);

  if (!isAuthenticated) {
    return (
      <div className="flex flex-col">
        <div className="min-h-screen flex flex-col items-center justify-center">
          <h1 className="text-4xl font-bold">
            {error_message.generic.access_denied}
          </h1>
          <p className="text-lg mt-4">{error_message.generic.admin_route}</p>
        </div>
      </div>
    );
  }

  return <Component {...rest} />;
};

export default AdminRoute;
