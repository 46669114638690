import React from "react";
import { Link } from "react-router-dom";

const MenuItem = ({ icon, link, text, onClick, isLastItem, disabled }) => (
  <div className="flex items-center">
    {isLastItem && (
      <div className="h-8 border-l-2 border-gray-500 mr-5 hidden md:block"></div>
    )}
    <svg
      width={icon.width}
      height={icon.height}
      viewBox={icon.viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={icon.path}
        stroke="#6E84A3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    {link && !disabled ? (
      <Link to={link}>
        <div className="link-text ml-2">{text}</div>
      </Link>
    ) : (
      <div className="link-text ml-2" onClick={onClick}>
        {text}
      </div>
    )}
  </div>
);

export const HeaderItems = ({
  items,
  topPadding,
  useDivider = true,
  disabled = false,
}) => (
  <div className={`flex flex-wrap mt-${topPadding}`}>
    <div className="flex-grow flex gap-2.5 space-x-4 items-center justify-start flex-wrap md:flex-nowrap">
      {items.map((item, index) => (
        <MenuItem
          key={index}
          icon={item.icon}
          link={item.link}
          text={item.text}
          onClick={item.onClick}
          disabled={disabled}
          isLastItem={
            useDivider ? items.length > 1 && index === items.length - 1 : false
          }
        />
      ))}
    </div>
  </div>
);
