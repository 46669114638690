import { TENANT } from "../types/tenant";

export const BlankTenantData: TENANT = {
  id: 0,
  code: "",
  name: "",
  name_kana: "",
  name_english: "",
  postal_code: "",
  address: "",
  phone_number: "",
  url: "",
  local_auth: true,
  google_auth: false,
  microsoft_365_auth: false,
  require_passcode_for_phonebook: false,
  disable_call_recording: false,
};
