import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "apps",
  initialState: {
    availableApps: [],
    appsChecked: false,
  },
  reducers: {
    setAppsCheckedState: (state, action) => {
      state.appsChecked = action.payload;
    },
    reset: (state) => {
      state.availableApps = [];
    },
    addApp: (state, action) => {
      state.availableApps = [...state.availableApps, action.payload];
    },
    removeApp: (state, action) => {
      state.availableApps = state.availableApps.filter(
        (app) => app !== action.payload,
      );
    },
  },
});

export const { reset, addApp, removeApp, setAppsCheckedState } =
  appSlice.actions;

export default appSlice.reducer;
