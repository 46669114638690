import React, { useEffect, useState } from "react";
import { getCompanyContractData } from "../../services/API/Requests";
import LoadingIcon from "../../common/LoadingIcon";
import { CONTRACT } from "../../types/contract";
import { error_message } from "../../constants/Errors";
import { CONTRACT_STATUS } from "../../constants/Enums";

/**
 * TenantContractTable - A component for displaying a table of application contracts and their statuses for a tenant.
 *
 * This component fetches and displays information about the company's application contracts,
 * including the application name, contract status, number of users, contract period, and more.
 * It utilizes the `DisplayStatus` sub-component to render each row of contract data.
 *
 * State:
 * - contractData {CONTRACT[]} - An array of license contract objects representing the current licenses of the tenant.
 * - error {string} - A string to store any error messages during data fetching.
 * - loading {boolean} - A boolean that indicates whether the data is currently being loaded.
 *
 * Sub-components:
 * - DisplayStatus - A sub-component responsible for rendering each row of the contracts table.
 *
 * Props:
 * - No props are passed to this component directly, but data is fetched and managed internally.
 *
 * Data Fetching:
 * - Fetches the contracts data from the `getCompanyContractData` API and stores it in the `contractData` state.
 *
 * Display:
 * - Displays a table with columns for the application name, contract status, user count, contract period, and number of users.
 * - Shows a loading indicator while data is being fetched and an error message if data fetching fails.
 *
 * Usage:
 * <TenantContractTable />
 */

const DisplayStatus = ({ contract }: { contract: CONTRACT }) => {
  const displayStatus =
    contract.status === CONTRACT_STATUS.ACTIVE
      ? "契約"
      : contract.status === CONTRACT_STATUS.INACTIVE
      ? "未契約"
      : contract.status === CONTRACT_STATUS.EXPIRED
      ? "契約終了"
      : "不明";

  const statusTextClass =
    contract.status === CONTRACT_STATUS.INACTIVE
      ? "text-red-500"
      : contract.status === CONTRACT_STATUS.EXPIRED
      ? "text-yellow-500"
      : "text-gray-900";

  const hasContract = contract.status === CONTRACT_STATUS.ACTIVE;

  const validateEndDate = (endDate) => {
    if (!endDate) {
      return false;
    } else {
      const year = new Date(endDate).getFullYear();
      return year < 2999;
    }
  };

  return (
    <tr key={contract.id}>
      <td className="py-4 whitespace-nowrap text-xl font-medium text-gray-900 w-1/5">
        {contract.app_name}
      </td>
      <td
        className={`py-4 whitespace-nowrap text-xl font-large ${statusTextClass} w-1/5`}
      >
        {displayStatus}
      </td>
      <td className={`py-4 whitespace-nowrap text-xl ${statusTextClass} w-1/5`}>
        {hasContract ? `${contract.contract_count || "0"}` : displayStatus}
      </td>
      <td
        className={`py-4 whitespace-nowrap text-xl font-large ${statusTextClass} w-1/5`}
      >
        {contract.start_date
          ? `${contract.start_date}~${
              validateEndDate(contract.end_date) ? contract.end_date : ""
            }`
          : "ー"}
      </td>
      <td
        className={`pl-5 py-4 whitespace-nowrap text-xl font-large ${statusTextClass} w-1/5`}
      >
        {contract.status === CONTRACT_STATUS.INACTIVE
          ? "ー"
          : contract.current_users || "0"}
      </td>
    </tr>
  );
};

const TenantContractTable = ({ id }: { id: string | null }) => {
  const [contractData, setContractData] = useState<CONTRACT[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const activeLicenses = contractData.filter(
    (contract) => contract.status === "active",
  ).length;

  useEffect(() => {
    const fetchContractData = async () => {
      setLoading(true);
      try {
        await getCompanyContractData(setContractData, setError, setLoading, id);
      } catch (error) {
        setError(error.message || "");
      } finally {
        setLoading(false);
      }
    };

    fetchContractData();
  }, [id]);

  return (
    <div className="overflow-x-auto">
      {loading ? (
        <LoadingIcon />
      ) : error ? (
        <p className="text-red-500">Error: {error}</p>
      ) : activeLicenses >= 1 ? (
        <table className="min-w-full divide-y divide-mayo-divider-dark table-fixed">
          <thead>
            <tr>
              <th
                scope="col"
                className="pb-5 text-left text-xl font-medium text-font-label-blue uppercase tracking-wider w-1/5"
              >
                アプリ
              </th>
              <th
                scope="col"
                className="pb-5 text-left text-xl font-medium text-font-label-blue uppercase tracking-wider w-1/5"
              >
                契約状態
              </th>
              <th
                scope="col"
                className="pb-5 text-left text-xl font-medium text-font-label-blue uppercase tracking-wider w-1/5"
              >
                ライセンス数
              </th>
              <th
                scope="col"
                className="pb-5 text-left text-xl font-medium text-font-label-blue uppercase tracking-wider w-1/5"
              >
                契約期間
              </th>
              <th
                scope="col"
                className="pl-5 pb-5 text-left text-xl font-medium text-font-label-blue tracking-wider w-1/5"
              >
                利用ユーザ数
              </th>
            </tr>
          </thead>
          <tbody>
            {contractData
              .filter(
                (contract) =>
                  contract.status !== CONTRACT_STATUS.EXPIRED &&
                  contract.status !== CONTRACT_STATUS.INACTIVE,
              )
              .map((contract) => (
                <DisplayStatus contract={contract} key={contract.id} />
              ))}
          </tbody>
        </table>
      ) : (
        <h1 className="flex text-center text-md">
          {error_message.tenant.no_active_contract}
        </h1>
      )}
    </div>
  );
};

export default TenantContractTable;
