export const sideBarLabels = {
  TENANT: {
    topTitle: "企業管理",
    companySettings: "企業情報設定",
    viewCompanies: "企業一覧",
    createCompany: "企業追加",
  },
  KAKELY: {
    topTitle: "KAKELY管理",
    departmentView: "部署一覧",
    phonebookView: "社外電話帳一覧",
    csvUpload: "電話帳CSVアップロード",
  },
  YOBELY: {
    topTitle: "YOBELY管理",
    viewGroups: "一斉発信グループ一覧・登録",
  },
  USER: {
    topTitle: "ユーザ管理",
    view: "ユーザ一覧",
    create: "ユーザ新規追加",
    csvUpload: "ユーザCSVアップロード",
    personalTopTile: "個人情報",
    myPage: "マイページ",
    passwordChange: "パスワード変更",
  },
  NOTIFICATION: {
    topTitle: "お知らせ管理",
    view: "お知らせ一覧",
    create: "お知らせ追加",
  },
  WORKJOY: {
    topTitle: "WORKJOY管理",
    hub: "メインページ",
    manageMentalCare: "メンタルヘルスケア管理",
    manageSettings: "スタンプ管理",
    biToolSettings: "BIツール",
  },
  GENERIC: {
    homeTopTitle: "HOME",
  },
};
