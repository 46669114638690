// map for common http status codes
export const HttpStatusCodes = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
};

// generate generic responses using the resource (user, tenant, etc ) and the mapped response status code.
export const handleResponse = (response, resource = "リソース") => {
  let message;
  switch (response.status) {
    case HttpStatusCodes.OK:
      message = `${resource}のリクエストが成功しました。`;
      break;
    case HttpStatusCodes.CREATED:
      message = `${resource}が正常に作成されました。`;
      break;
    case HttpStatusCodes.NO_CONTENT:
      message = `${resource}に表示するコンテンツがありません。`;
      break;
    case HttpStatusCodes.BAD_REQUEST:
      message = `${resource}のリクエストが不正です。データを確認してください。`;
      break;
    case HttpStatusCodes.UNAUTHORIZED:
      message = `${resource}に対するリクエストは認証されていません。ログインしてください。`;
      break;
    case HttpStatusCodes.FORBIDDEN:
      message = `${resource}へのアクセスが拒否されました。`;
      break;
    case HttpStatusCodes.NOT_FOUND:
      message = `${resource}が見つかりませんでした。`;
      break;
    case HttpStatusCodes.CONFLICT:
      message = `${resource}のリクエストに矛盾があります。`;
      break;
    case HttpStatusCodes.INTERNAL_SERVER_ERROR:
      message = `${resource}の処理中にサーバーエラーが発生しました。`;
      break;
    case HttpStatusCodes.BAD_GATEWAY:
      message = `ゲートウェイエラーが発生しました。${resource}のリクエストに影響があります。`;
      break;
    case HttpStatusCodes.SERVICE_UNAVAILABLE:
      message = `${resource}サービスは現在利用できません。`;
      break;
    default:
      message = `未処理のステータスコード: ${response.status} (${resource})`;
  }

  return {
    message: message,
    status: response.status,
  };
};
