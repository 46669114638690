import { useEffect, useMemo, useRef, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { useKeycloak } from "@react-keycloak/web";
import {
  setAuthState,
  setIsAdmin,
  setIsFirstUser,
} from "./services/StateManagement/AuthSlice";
import { useDispatch, useSelector } from "react-redux";

import { setCompanyIdState } from "./services/StateManagement/CompanySlice";
import { setUserId } from "./services/StateManagement/UserSlice";
import { setHeaders } from "./services/StateManagement/ApiSlice";

import Loader from "./common/Loader";
import PageTitle from "./common/PageTitle";
import React from "react";

import DefaultLayout from "./layout/DefaultLayout";

import NotificationMainPage from "./pages/Notification/NotificationMainPage";
import ViewNotificationPage from "./pages/Notification/ViewNotificationPage";
import CreateNotificationPage from "./pages/Notification/CreateNotificationPage";

import ViewTenantPage from "./pages/Tenant/ViewTenantPage";

import HubMainPage from "./pages/WORKJOY/HubMainPage";
import HomeMainPage from "./pages/Home/HomeMainPage";

import CreateUserPage from "./pages/User/CreateUserPage";
import KakelyCsvUploadPage from "./pages/KAKELY/KakelyCsvUploadPage";
import CreatePhonebookPage from "./pages/KAKELY/CreatePhonebookPage";

import UserMainPage from "./pages/User/UserMainPage";
import ViewPhonebookPage from "./pages/KAKELY/ViewPhonebookPage";
import ViewUserPage from "./pages/User/ViewUserPage";
import ViewCurrentUserPage from "./pages/User/ViewUserPage";
import UserCSVUploadPage from "./pages/User/UserCsvUploadPage";
import UserPhotoUploadPage from "./pages/User/UserPhotoUploadPage";
import PasswordResetPage from "./pages/User/PasswordResetPage";

import AISettingsPage from "./pages/WORKJOY/AISettingsPage";
import BiToolPage from "./pages/WORKJOY/BiToolPage";

import YobelyMainPage from "./pages/YOBELY/YobelyMainPage";
import CreateTenantPage from "./pages/Tenant/CreateTenantPage";

import DepartmentGroupMainPage from "./pages/KAKELY/DepartmentGroupMainPage";
import EditDepartmentGroupPage from "./pages/KAKELY/EditDepartmentGroupPage";
import CreateDepartmentGroupPage from "./pages/KAKELY/CreateDepartmentGroupPage";

import StampSettingsPage from "./pages/WORKJOY/StampSettingsPage";
import MentalHealthPage from "./pages/WORKJOY/MentalHealthPage";

import PrivateRoute from "./components/Routes/PrivateRoute";
import NotFound from "./components/ErrorPages/PageNotFound";
import AdminRoute from "./components/Routes/AdminRoute";
import TenantAdminPage from "./pages/Tenant/TenantAdminPage";
import ContractRoute from "./components/Routes/ContractRoute";
import KakelyMainPage from "./pages/KAKELY/KakelyMainPage";
import useApiConnection from "./services/CheckConnection";
import NoConnectionModal from "./components/Modals/NoConnectionModal";
import RegularRoute from "./components/Routes/RegularRoute";
import { error_message } from "./constants/Errors";
import messages from "./constants/Messages";
import {
  COMPANY_PATHS,
  GENERIC_PATHS,
  KAKELY_PATHS,
  NOTIFICATION_PATHS,
  USER_PATHS,
  WORKJOY_PATHS,
  YOBELY_PATHS,
} from "./constants/NavigationPaths";
import { ToastContainer } from "react-toastify";
import { pageTitles } from "./constants/PageTitles";
import { validateUserExists } from "./services/API/Requests";
import { USER } from "./types/user";
import NoUserFoundModal from "./components/Modals/NoUserFoundModal";
import { LOGIN_STATES } from "./constants/Enums";

function App() {
  const { pathname } = useLocation();
  const { keycloak, initialized } = useKeycloak();
  const dispatch = useDispatch();

  const adminRealm: string = import.meta.env.VITE_ADMIN_REALM;
  const isAdminApplication: boolean =
    import.meta.env.VITE_ADMIN_APPLICATION === "true";
  const isAdminLoggedIn: boolean = useSelector((state) => state.auth.isAdmin);

  const managementPage = import.meta.env.VITE_APP_MNG_HOME_PAGE;
  const adminHomePage: string = import.meta.env.VITE_APP_ADMIN_HOME_PAGE;

  const realm: string | null = localStorage.getItem("realm");

  if (isAdminApplication && realm != adminRealm) {
    localStorage.setItem("realm", adminRealm);
    window.location.href = managementPage;
  } else if (!isAdminApplication && realm == adminRealm) {
    localStorage.setItem("realm", "");

    localStorage.setItem("previousState", LOGIN_STATES.LOGGED_IN);
    localStorage.setItem("logInState", LOGIN_STATES.LOGGING_OUT);

    window.location.href = adminHomePage;
  }

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<String>("");

  const [sessionExpired, setSessionExpired] = useState<boolean>(false);

  const [isConnected, setIsConnected] = useState<boolean | undefined>(false);

  const [connectionChecked, setConnectionChecked] = useState<boolean>(false);

  const [userChecked, setUserChecked] = useState<boolean>(false);

  const [userData, setUserData] = useState<USER>();
  const [noUserFound, setNoUserFound] = useState<boolean>(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        await validateUserExists(
          setUserData,
          setLoading,
          setError,
          0,
          true,
        ).then((response) => {
          if (response.user_id) {
            setNoUserFound(false);
          } else {
            setNoUserFound(true);
          }
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (userChecked) {
      fetchUserData();
    }
  }, [userChecked]);

  useEffect(() => {
    const logInState = localStorage.getItem("logInState");

    if (logInState === LOGIN_STATES.START) {
      localStorage.setItem("previousState", LOGIN_STATES.LOGGING_IN);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    const checkConnection = async () => {
      if (connectionChecked) {
        return;
      }
      try {
        const connectionStatus = await useApiConnection();
        if (connectionStatus !== null) {
          setIsConnected(connectionStatus);
        }
        setConnectionChecked(true);
      } catch (err) {
        setIsConnected(false);
        setConnectionChecked(false);
      }
    };

    const handleUserSettings = async () => {
      if (!keycloak || !keycloak.token) return;

      dispatch(
        setHeaders({
          Authorization: `Bearer ${keycloak.token}`,
        }),
      );

      try {
        const userInfo: {} = await keycloak.loadUserInfo();

        if (userInfo) {
          dispatch(setAuthState(true));
          dispatch(setCompanyIdState(keycloak.realm));
          dispatch(setUserId(userInfo?.sub));
          setUserChecked(true);

          const userGroups: string[] | undefined =
            keycloak.tokenParsed?.realm_access?.roles;

          if (userGroups?.includes("admins")) {
            if (realm === adminRealm) {
              if (!isAdminLoggedIn) {
                dispatch(setIsAdmin(true));
              }
            } else {
              dispatch(setIsFirstUser(true)); // track the first user, since we don't have login details to display
            }
          } else {
            dispatch(setIsFirstUser(false)); // track the first user, since we don't have login details to display
          }

          return () => clearInterval(refreshTokenInterval);
        }
      } catch (err) {
        console.error(error_message.user.not_loaded, err);
      }
    };

    handleUserSettings();
    checkConnection();
  });

  const refreshTokenInterval = setInterval(async () => {
    try {
      await keycloak.updateToken().then(() => {
        dispatch(
          setHeaders({
            Authorization: `Bearer ${keycloak.token}`,
          }),
        );
      });
    } catch (err) {
      setSessionExpired(true);
      console.error(error_message.keycloak.token_refresh, err);
    }
  }, 50000);

  useEffect(() => {
    if (sessionExpired) {
      localStorage.setItem("logInState", LOGIN_STATES.LOGGING_OUT);
    }
  }, [sessionExpired]);

  const checkTokenExpiration = () => {
    if (keycloak && keycloak.authenticated) {
      console.info(messages.keycloak.token_authed);
    } else {
      console.warn(error_message.generic.session_expired);
      setSessionExpired(true);
    }
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      if (!initialized) {
        console.info(messages.keycloak.wait_for_init);
        return;
      }

      if (keycloak.authenticated) {
        localStorage.setItem("logInState", LOGIN_STATES.LOGGED_IN);
        checkTokenExpiration();
      } else {
        if (localStorage.getItem("logInState") === LOGIN_STATES.LOGGED_IN) {
          localStorage.setItem("logInState", LOGIN_STATES.LOGGING_OUT);
        } else {
          localStorage.setItem("logInState", LOGIN_STATES.LOGGING_IN);
          await keycloak.login();
        }
      }
    };

    if (initialized) {
      checkAuthentication();
    }
  }, [initialized, keycloak]);

  if (
    connectionChecked === true &&
    isConnected === false &&
    localStorage.getItem("logInState") === LOGIN_STATES.LOGGED_IN
  ) {
    return <NoConnectionModal />;
  }

  // if (noUserFound && !loading) {
  //   return <NoUserFoundModal></NoUserFoundModal>;
  // }

  return loading ? (
    <Loader />
  ) : (
    <DefaultLayout>
      <ToastContainer></ToastContainer>
      <Routes>
        <Route
          index
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={pageTitles.GENERIC.login} />
                  {isAdminLoggedIn ? <UserMainPage /> : <HomeMainPage />}
                </>
              )}
            />
          }
        />
        <Route
          path={NOTIFICATION_PATHS.main}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={pageTitles.NOTIFICATION.main} />
                  <NotificationMainPage />
                </>
              )}
            />
          }
        />
        <Route
          path={NOTIFICATION_PATHS.newNotification}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={pageTitles.NOTIFICATION.create} />
                  <CreateNotificationPage />
                </>
              )}
            />
          }
        />
        <Route
          path={NOTIFICATION_PATHS.viewNotification}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={pageTitles.NOTIFICATION.edit} />
                  <ViewNotificationPage />
                </>
              )}
            />
          }
        />
        <Route
          path={NOTIFICATION_PATHS.createNotification}
          element={
            <AdminRoute
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={pageTitles.NOTIFICATION.edit} />
                      <CreateNotificationPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={COMPANY_PATHS.listCompanies}
          element={
            <AdminRoute
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={pageTitles.TENANT.main} />
                      <TenantAdminPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={COMPANY_PATHS.showCompany}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={pageTitles.TENANT.manage} />
                  <ViewTenantPage />
                </>
              )}
            />
          }
        />
        <Route
          path={COMPANY_PATHS.manageCompany}
          element={
            <AdminRoute
              element={() => (
                <>
                  <PrivateRoute
                    element={() => (
                      <>
                        <PageTitle title={pageTitles.TENANT.manage} />
                        <ViewTenantPage />
                      </>
                    )}
                  />
                </>
              )}
            />
          }
        />
        {/* <Route
          path="company/manage/csvUpload"
          element={
            <AdminRoute
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={`企業CSVアップロード | ${APPNAME}`} />
                      <TenantCsvUploadPage />
                    </>
                  )}
                />
              )}
            />
          }
        /> */}
        <Route
          path={COMPANY_PATHS.createCompany}
          element={
            <AdminRoute
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={pageTitles.TENANT.create} />
                      <CreateTenantPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={GENERIC_PATHS.settings}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={pageTitles.GENERIC.settings} />
                  <ViewCurrentUserPage />
                </>
              )}
            />
          }
        />
        <Route
          path={GENERIC_PATHS.home}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={pageTitles.GENERIC.home} />
                  <HomeMainPage />
                </>
              )}
            />
          }
        />
        <Route
          path={KAKELY_PATHS.main}
          element={
            <ContractRoute
              appName="KAKELY"
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={pageTitles.KAKELY.main} />
                      <KakelyMainPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={KAKELY_PATHS.allDepartmentSettings}
          element={
            <ContractRoute
              appName="KAKELY"
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={pageTitles.KAKELY.departmentView} />
                      <DepartmentGroupMainPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={KAKELY_PATHS.departmentSettings}
          element={
            <ContractRoute
              appName="KAKELY"
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={pageTitles.KAKELY.departmentSettings} />
                      <EditDepartmentGroupPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={KAKELY_PATHS.createDepartment}
          element={
            <ContractRoute
              appName="KAKELY"
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={pageTitles.KAKELY.departmentSettings} />
                      <CreateDepartmentGroupPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={KAKELY_PATHS.csvUpload}
          element={
            <RegularRoute
              element={() => (
                <ContractRoute
                  appName="KAKELY"
                  element={() => (
                    <PrivateRoute
                      element={() => (
                        <>
                          <PageTitle title={pageTitles.GENERIC.csvUpload} />
                          <KakelyCsvUploadPage />
                        </>
                      )}
                    />
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={KAKELY_PATHS.createContact}
          element={
            <ContractRoute
              appName="KAKELY"
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={pageTitles.KAKELY.phonebookEdit} />
                      <CreatePhonebookPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={KAKELY_PATHS.showUser}
          element={
            <ContractRoute
              appName="KAKELY"
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={pageTitles.KAKELY.phonebookView} />
                      <ViewPhonebookPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={USER_PATHS.main}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={pageTitles.USER.main} />
                  <UserMainPage />
                </>
              )}
            />
          }
        />
        <Route
          path={USER_PATHS.showUser}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={pageTitles.USER.edit} />
                  <ViewUserPage />
                </>
              )}
            />
          }
        />
        <Route
          path={USER_PATHS.csvUpload}
          element={
            <RegularRoute
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={pageTitles.GENERIC.csvUpload} />
                      <UserCSVUploadPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={USER_PATHS.photoUpload}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={pageTitles.USER.photoUpload} />
                  <UserPhotoUploadPage />
                </>
              )}
            />
          }
        />
        <Route
          path={USER_PATHS.createUser}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={pageTitles.USER.create} />
                  <CreateUserPage />
                </>
              )}
            />
          }
        />
        <Route
          path={USER_PATHS.passwordReset}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={pageTitles.USER.changePassword} />
                  <PasswordResetPage />
                </>
              )}
            />
          }
        />
        <Route
          path={WORKJOY_PATHS.hub}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={pageTitles.WORKJOY.main} />
                  <HubMainPage />
                </>
              )}
            />
          }
        />
        <Route
          path={WORKJOY_PATHS.manageMentalCare}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={pageTitles.WORKJOY.manageMentalCare} />
                  <MentalHealthPage />
                </>
              )}
            />
          }
        />
        <Route
          path={WORKJOY_PATHS.manageAISettings}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={pageTitles.WORKJOY.manageAISettings} />
                  <AISettingsPage />
                </>
              )}
            />
          }
        />
        <Route
          path={WORKJOY_PATHS.manageSettings}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={pageTitles.WORKJOY.manageSettings} />
                  <StampSettingsPage />
                </>
              )}
            />
          }
        />
        <Route
          path={WORKJOY_PATHS.biToolSettings}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={pageTitles.WORKJOY.biToolSettings} />
                  <BiToolPage />
                </>
              )}
            />
          }
        />
        {/* <Route <- path removed at request of tokujisan 2025-01-20
          path={YOBELY_PATHS.main}
          element={
            <ContractRoute
              appName="YOBELY"
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={pageTitles.YOBELY.main} />
                      <YobelyMainPage />
                    </>
                  )}
                />
              )}
            />
          }
        /> */}
        <Route
          path="*"
          element={
            <>
              <PageTitle title={pageTitles.GENERIC.not_found} />
              <NotFound />
            </>
          }
        />
      </Routes>
    </DefaultLayout>
  );
}

export default App;
