import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userId: null,
    user: null,
  },
  reducers: {
    setUserState: (state, action) => {
      state.user = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    resetUserId: (state) => {
      state.userId = null;
    },
    resetUserState: (state) => {
      state.user = null;
    },
  },
});

export const { setUserId, resetUserId, setUserState, resetUserState } =
  userSlice.actions;

export default userSlice.reducer;
