import React from "react";
import { useSelector } from "react-redux";
import NoAuthModal from "../Modals/NoAuthModal";

/**
 * PrivateRoute - A higher-order component that restricts access to routes based on user authentication status.
 *
 * This component checks if the user is authenticated by verifying the `status` in the Redux state.
 * If the user is not authenticated, it displays a modal indicating no access (`NoAuthModal`).
 * If the user is authenticated, it renders the provided component.
 *
 * @param {Object} props - The props object.
 * @param {React.ComponentType} props.element - The component to be rendered if the user is authenticated.
 * @param {...Object} rest - The remaining props that are passed to the component.
 * @returns {JSX.Element} - The restricted component if authenticated, otherwise a modal indicating no access.
 *
 * Usage:
 * <PrivateRoute element={YourComponent} someProp="someValue" />
 */
const PrivateRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.status);

  if (!isAuthenticated) {
    return <NoAuthModal />;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
