import React from "react";
import TableNotificationInfo from "../../components/Tables/NotificationTable";
import { TitleWithDivider } from "../../common/TitleWithDivider";

const NotificationMainPage: React.FC = () => {
  return (
    <>
      <div>
        <div>
          <TitleWithDivider titleText={"お知らせ一覧"} useDivider={true} />
          <TableNotificationInfo />
        </div>
      </div>
    </>
  );
};

export default NotificationMainPage;
