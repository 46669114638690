import React from "react";
import { TitleWithDivider } from "../../common/TitleWithDivider.tsx";
import ContactProfileForm from "../../components/Forms/ContactProfileForm";

const ViewContactPage: React.FC = () => {
  return (
    <>
      <div>
        <TitleWithDivider titleText="電話帳登録・編集画面" useDivider={true} />
        <ContactProfileForm></ContactProfileForm>
      </div>
    </>
  );
};

export default ViewContactPage;
