import { createSlice } from "@reduxjs/toolkit";

export const apiSlice = createSlice({
  name: "api",
  initialState: {
    headers: {
      Authorization: null,
    },
  },
  reducers: {
    setHeaders: (state, action) => {
      state.headers = action.payload;
    },
  },
});

export const { setHeaders } = apiSlice.actions;

export default apiSlice.reducer;
