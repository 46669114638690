import React from "react";
import LoadingIcon from "../../common/LoadingIcon";

interface LoadingModalProps {
  isOpen: boolean;
}
/**
 * LoadingModal - A modal component to display a loading spinner while data or processes are being handled.
 *
 * This component renders a modal overlay with a loading spinner to indicate that an operation is in progress.
 * It is useful for blocking user interactions while waiting for data or performing an asynchronous action.
 * The modal is only visible when the `isOpen` prop is set to true.
 *
 * @param {Object} props - The props object.
 * @param {boolean} props.isOpen - A flag indicating whether the modal is open and the loading spinner should be displayed.
 * @returns {JSX.Element | null} - A JSX element representing the loading modal if `isOpen` is true, or null if the modal is closed.
 *
 * Usage:
 * <LoadingModal isOpen={isLoading} />
 */

const LoadingModal: React.FC<LoadingModalProps> = ({ isOpen }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center backdrop-brightness-50 z-[1000]">
      <div className="bg-white border border-text-field-border rounded-lg w-50">
        <div className="mayo-card-body p-4 flex justify-between items-center"></div>
        <LoadingIcon></LoadingIcon>
        <div className="flex justify-end gap-2.5 mb-5 mr-4"></div>
      </div>
    </div>
  );
};

export default LoadingModal;
