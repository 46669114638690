import { useEffect, useRef, useState } from "react";
import useDrag from "./DragHandler";
import useClickOutside from "./ClickOutsideHandler";
import React from "react";
import { XCircleIcon } from "@heroicons/react/16/solid";
import { USER_TYPE } from "../../constants/Enums";
import { METHOD_TYPE_TRANSLATIONS } from "../../services/TranslateCsvHeaders";

interface FailedUsersModalProps {
  failedUsers: [] | undefined;
  confirmText: string;
  cancelText?: string;
  confirmButtonColor?: string;
  isOpen: boolean;
  onRequestClose: () => void;
  removeFromCsv: (id) => void;
}

const FailedUsersModal: React.FC<FailedUsersModalProps> = ({
  failedUsers,
  cancelText = "キャンセル",
  isOpen,
  onRequestClose,
  removeFromCsv,
}) => {
  const modalRef: React.RefObject<HTMLDivElement> =
    useRef<HTMLDivElement>(null);

  const [localFailedUsers, setLocalFailedUsers] = useState(failedUsers);

  useEffect(() => {
    setLocalFailedUsers(failedUsers);
  }, [failedUsers]);

  const { position, handleMouseDown } = useDrag(modalRef, isOpen);
  useClickOutside(isOpen, onRequestClose, modalRef);

  const handleDeleteUser = (index: number) => {
    setLocalFailedUsers((prev) => {
      const userToDelete = prev[index];
      removeFromCsv(userToDelete.index);
      return prev.filter((_, i) => i !== index);
    });
  };

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center backdrop-brightness-50 z-[1000]">
      <div
        ref={modalRef}
        onMouseDown={handleMouseDown}
        style={{
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
        className="bg-white border border-text-field-border rounded-lg max-w-[90vw] sm:max-w-[80vw] md:max-w-[60vw] lg:max-w-[50vw] xl:max-w-[40vw] max-h-[90vh] overflow-y-auto"
      >
        <div className="mayo-card-header p-4 flex justify-between items-center">
          <h2 className="text-lg font-semibold">ユーザー登録エラー</h2>
          <button onClick={onRequestClose} className="text-xl hover:opacity-50">
            <svg
              className="ml-2 fill-body hover:fill-primary"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <XCircleIcon />
            </svg>
          </button>
        </div>

        <div className="p-4">
          <div className="p-4">
            <table className="table-auto w-full border-collapse">
              <thead>
                <tr className="bg-gray-100 text-gray-600">
                  <th className="border-b border-gray-300 p-3 text-left w-1/4">
                    ユーザ名
                  </th>
                  <th className="border-b border-gray-300 p-3 text-left w-1/4">
                    ユーザID
                  </th>
                  <th className="border-b border-gray-300 p-3 text-left w-1/3">
                    エラー
                  </th>
                  <th className="border-b border-gray-300 p-3 text-left w-1/5">
                    操作
                  </th>
                  <th className="border-b border-gray-300 p-3 text-center w-[100px] whitespace-nowrap">
                    削除
                  </th>
                </tr>
              </thead>
              <tbody>
                {localFailedUsers?.map((user, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-50 transition-colors ease-in-out"
                  >
                    <td className="border-t border-gray-300 p-3">
                      {user.user?.last_name} {user.user?.first_name}
                    </td>
                    <td className="border-t border-gray-300 p-3">
                      {user.user?.user_type === USER_TYPE.INTERNAL
                        ? user.user?.login_id
                        : "社外"}
                    </td>
                    <td className="border-t border-gray-300 p-3">
                      {user.error}
                    </td>
                    <td className="border-t border-gray-300 p-3">
                      {METHOD_TYPE_TRANSLATIONS[user.method] || "未知の操作"}
                    </td>
                    <td className="border-t border-gray-300 p-3 text-center">
                      <button
                        className="text-red-600 hover:text-red-800 transition-colors ease-in-out"
                        onClick={() => handleDeleteUser(index)}
                        title="削除"
                      >
                        <XCircleIcon className="w-5 h-5 mx-auto" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex justify-end gap-2.5 mb-5 mr-4">
          <button
            onClick={onRequestClose}
            className="button-small-white border-text-field-border"
          >
            {cancelText === "" ? "キャンセル" : cancelText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FailedUsersModal;
