import React, { useRef } from "react";
import StampTable from "../../components/Tables/StampTable";
import { TitleWithDivider } from "../../common/TitleWithDivider";
import { PlusCircleIcon } from "@heroicons/react/24/outline";

const StampSettingsPage: React.FC = () => {
  const fileInput = useRef(null);

  return (
    <>
      <div>
        <TitleWithDivider
          titleText="スタンプ管理"
          useDivider={true}
        ></TitleWithDivider>
        <div className="border border-text-field-border rounded-xl border-r-1">
          <div className="mayo-card-body flex justify-center">
            <div className="flex flex-row hover:opacity-50">
              <svg
                width="26"
                height="26"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <PlusCircleIcon className="text-mayo-link-text"></PlusCircleIcon>
              </svg>
              <button
                className="ml-1 flex items-center text-xl text-mayo-link-text"
                onClick={() => fileInput.current.click()}
              >
                新規アップロード
              </button>
            </div>
            <div className="flex mt-5">
              <input
                type="file"
                onChange={undefined}
                accept=".png,.jpg,.jpeg"
                ref={fileInput}
                style={{ display: "none" }}
                className="flex items-center w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-xl file:font-semibold file:bg-black file:text-white hover:file:opacity-60"
              />
            </div>
          </div>
        </div>
        <div className="mt-10"></div>
        <StampTable onUserSelect={undefined}></StampTable>
      </div>
    </>
  );
};

export default StampSettingsPage;
