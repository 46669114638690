import React, { useState, useEffect, useRef } from "react";
import {
  MagnifyingGlassIcon,
  PlusCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import useClickOutside from "./ClickOutsideHandler";
import {
  getFilteredSkilltagData,
  getSkillTagData,
} from "../../services/API/Requests";
import { TALKGROUP } from "../../types/talkgroup";
import CreateSkillTagModal from "./CreateSkilltagModal";
import useDrag from "./DragHandler";

interface SkillTagModal {
  isOpen: boolean;
  onRequestClose: () => void;
  onSelectGroup: (group) => void;
}

/**
 * SkillTagModal - A modal component for selecting and managing skill tags.
 *
 * This component displays a modal dialog that allows users to search for and select a skill tag from a list.
 * It provides a search bar for filtering tags based on the user's input and displays a list of matching tags.
 * Each tag in the list has a "選択" button that allows the user to select it. Users can also create new skill
 * tags using the "新スキルタグ追加" button, which opens a secondary modal for creating a new tag.
 * The modal is draggable and can be closed using the "X" button.
 *
 * @param {Object} props - The props object.
 * @param {boolean} props.isOpen - A flag indicating whether the modal is open.
 * @param {function} props.onRequestClose - A callback function to close the modal.
 * @param {function} props.onSelectGroup - A callback function called with the selected skill tag data when a tag is selected.
 * @returns {JSX.Element | null} - A JSX element representing the modal if `isOpen` is true, or null if the modal is closed.
 *
 * Usage:
 * <SkillTagModal
 *   isOpen={isModalOpen}
 *   onRequestClose={closeModalHandler}
 *   onSelectGroup={handleSelectTag}
 * />
 */

const SkillTagModal: React.FC<SkillTagModal> = ({
  isOpen,
  onSelectGroup,
  onRequestClose,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [skillTags, setSkillTags] = useState<never[]>([]);
  const [filteredGroups, setFilteredGroups] = useState<never[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);

  const modalRef = useRef<HTMLDivElement>(null);
  const { position, handleMouseDown } = useDrag(modalRef, isOpen);
  useClickOutside(isOpen, onRequestClose, modalRef);

  const handleCreateTag = (tagName: string) => {
    const newTag: {
      id: number;
      name: string;
    } = { id: skillTags.length + 1, name: tagName };
    // setSkillTags([...skillTags, newTag]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getSkillTagData(setSkillTags, setLoading, setError);
      } catch (err) {
        console.error(err);
      } finally {
        setSearchTerm("");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredGroups(skillTags);
    }
  }, [searchTerm, skillTags]);

  const doSearch = async () => {
    try {
      setLoading(true);
      if (!searchTerm) {
        setFilteredGroups(skillTags);
      } else {
        const query = `name=${searchTerm}`;
        await getFilteredSkilltagData(
          setFilteredGroups,
          setError,
          setLoading,
          query,
        );
      }
    } catch (error) {
      setError("検索に失敗しました。");
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-opacity-50 flex items-center justify-center backdrop-brightness-50">
      <div
        ref={modalRef}
        onMouseDown={handleMouseDown}
        style={{
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
        className="border border-black-10 bg-white rounded-xl w-200"
      >
        <div className="mayo-card-header p-4 flex justify-between items-center">
          <h2 className="text-lg font-semibold">スキルタグ選択</h2>
          <button onClick={onRequestClose} className="text-xl hover:opacity-50">
            <svg
              className="ml-2 fill-body hover:fill-primary"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <XCircleIcon></XCircleIcon>
            </svg>
          </button>
        </div>

        <div className="ml-10 mt-5">
          <div>
            <div className="flex items-center">
              <div className="relative">
                <svg
                  className="absolute left-2 top-1/2 -translate-y-1/2 fill-body hover:fill-primary"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <MagnifyingGlassIcon />
                </svg>
                <input
                  type="text"
                  className="search-bar pl-10"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <button
                className="ml-3 mr-8 bg-button-light-blue text-white px-5 py-4 rounded-xl flex space-x-4 items-center hover:opacity-50"
                onClick={doSearch}
              >
                {"タグ検索"}
              </button>
            </div>
          </div>
        </div>

        <div className="mb-6"></div>
        <div className="mt-10 px-5">
          <div className="flex items-center mb-6 ">
            <PlusCircleIcon className="h-5 w-5" />
            <button
              className="ml-2 link-text"
              onClick={() => setIsCreateModalOpen(true)}
            >
              新スキルタグ追加
            </button>
          </div>
          <table className="w-full">
            <tbody>
              {filteredGroups?.length === 0 ? (
                <td className="flex justify-between items-center p-5 text-xl text-black">
                  タグはありません。
                </td>
              ) : (
                filteredGroups?.map((group) => (
                  <React.Fragment key={group.id}>
                    <tr className="flex justify-between items-center mb-4 gap-2.5">
                      <td className="text-xl text-black">{group.name}</td>
                      <td>
                        <button
                          className="button-small-white text-white mr-2 py-1 px-4 rounded hover:opacity-50"
                          onClick={() => onSelectGroup(group)}
                        >
                          選択
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="divider"></div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <CreateSkillTagModal
        isOpen={isCreateModalOpen}
        onRequestClose={() => setIsCreateModalOpen(false)}
        onCreateTag={handleCreateTag}
      />
    </div>
  );
};

export default SkillTagModal;
