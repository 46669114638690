import React, { ChangeEvent, useState } from "react";

import { Link } from "react-router-dom";
import { TitleWithDivider } from "../../common/TitleWithDivider";

const AISettingsPage: React.FC = () => {
  const days = ["月", "火", "水", "木", "金", "土", "日"];
  const months = [
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月",
  ];

  const [selectedOption, setSelectedOption] = useState<string>("");

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  const [dayStates, setDayStates] = useState(Array(days.length).fill(false));
  const [monthStates, setMonthStates] = useState(
    Array(months.length).fill(false),
  );

  const handleDayChange = (index) => {
    const newDayStates = [...dayStates];
    newDayStates[index] = !newDayStates[index];
    setDayStates(newDayStates);
  };

  const handleMonthChange = (index) => {
    const newMonthStates = [...monthStates];
    newMonthStates[index] = !newMonthStates[index];
    setMonthStates(newMonthStates);
  };

  const reset = () => {
    setDayStates(Array(days.length).fill(false));
    setMonthStates(Array(months.length).fill(false));
  };

  return (
    <>
      <div>
        <TitleWithDivider
          titleText="メンタルヘルスケア管理"
          useDivider={true}
        />

        <div className="border border-text-field-border rounded-xl border-r-1 mt-10">
          <div className="mayo-card-body">
            <div className="flex flex-row justify-between items-center">
              <div className="label-light-blue-xl">AI 1 on 1設定</div>
              <button
                className="rounded-md bg-white py-2 min-w-40 max-w-45 text-center font-medium text-black border border-mayo-light-gray hover:bg-opacity-60 lg:px-8 mr-4"
                onClick={reset}
              >
                設定リセット
              </button>
            </div>
            <div className="divider mt-5"></div>
            <div className="label-light-blue-xl">実施頻度</div>
            <div className="ml-5 mb-2 text-xl text-black">
              <label className="flex items-center mt-5">
                <select
                  className="search-bar"
                  value={selectedOption}
                  onChange={handleChange}
                >
                  <option value="default" disabled>
                    未選択
                  </option>
                  <option value="weekly">毎週</option>
                  <option value="monthly">毎月</option>
                </select>
              </label>
            </div>
            <div className="divider mt-5"></div>
            <div className="label-light-blue-xl">実施曜日</div>
            <div className="flex flex-row gap-10 mt-5">
              {days.map((day, index) => (
                <div
                  key={index}
                  className="flex flex-row justify-items-center items-center mx-2"
                >
                  <input
                    type="checkbox"
                    className="w-4 h-4"
                    checked={dayStates[index]}
                    onChange={() => handleDayChange(index)}
                  />
                  <div className="text-xl ml-2">{day}</div>
                </div>
              ))}
            </div>
            {selectedOption === "monthly" && (
              <div className="flex flex-row gap-2.5 mt-5">
                <div className="flex flex-row gap-5 mt-5">
                  {months.map((month, index) => (
                    <div
                      key={index}
                      className="flex flex-row justify-items-center items-center mx-2"
                    >
                      <input
                        type="checkbox"
                        className="w-4 h-4"
                        checked={monthStates[index]}
                        onChange={() => handleMonthChange(index)}
                      />
                      <div className="text-xl ml-2">{month}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-end items-center gap-2.5 mt-30">
          <Link to="/hub/mentalheatlhCare/manage">
            <div className="button-cancel">キャンセル</div>
          </Link>
          <Link to="/hub/mentalheatlhCare/manage">
            <div className="button-confirm">登録</div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default AISettingsPage;
