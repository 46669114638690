import { EyeIcon, EyeSlashIcon } from "@heroicons/react/16/solid";
import React from "react";
import { useState } from "react";
import { handlePasswordChange } from "../../services/ErrorMessages";
import { UserFieldErrorMessages } from "../../constants/Errors";

interface PasswordItemProps {
  label: string;
  identifier: string;
  password: string;
  errors: boolean;
  modal: boolean;
  setError: (field: any, value: any) => void;
  setPassword: (password: string) => void;
}

/**
 * PasswordItem - A reusable component for displaying a password input field with a toggleable visibility option.
 *
 * This component renders a password input field with an optional label and a button to toggle
 * the visibility of the entered password. It allows the password to be shown or hidden based
 * on user interaction. The appearance of the component can be adjusted using the `modal` prop
 * for different styling scenarios.
 *
 * @param {Object} props - The props object.
 * @param {string} props.label - The label text displayed above the password input field.
 * @param {string} props.identifier - The identifier that references the type of error returned from the password field.
 * @param {string} props.password - The current password value to be displayed in the input field.
 * @param {boolean} props.modal - A flag indicating whether to use modal-specific styling for the input and button.
 * @param {field: any, value: any} props.setError - A callback function to update the error value of the parent component.
 * @param {function} props.setPassword - A callback function to update the password value in the parent component's state.
 * @returns {JSX.Element} - A JSX element representing a password input field with toggleable visibility.
 *
 * Usage:
 * <PasswordItem
 *   label="Password"
 *   password={password}
 *   modal={false}
 *   setPassword={setPassword}
 * />
 */

const PasswordItem: React.FC<PasswordItemProps> = ({
  label,
  password,
  identifier,
  setError,
  errors,
  modal,
  setPassword,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const verifyPassword = (value) => {
    handlePasswordChange(identifier, value, setPassword, setError);
  };

  return (
    <div className="password-item">
      <>
        <label className="mb-2.5 block font-medium text-mayo-light-gray">
          {label}
        </label>
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            required
            onChange={(e) => verifyPassword(e.target.value)}
            className={modal ? "password-textbox-modal" : "password-textbox"}
          />
          <button
            type="button"
            className={modal ? "password-eye-icon-modal" : "password-eye-icon"}
            onClick={toggleShowPassword}
          >
            {showPassword ? (
              <EyeSlashIcon className="w-6 h-6" />
            ) : (
              <EyeIcon className="w-6 h-6" />
            )}
          </button>
        </div>
        {errors && (
          <p className="text-red-500">{UserFieldErrorMessages.passwordError}</p>
        )}
      </>
    </div>
  );
};

export default PasswordItem;
