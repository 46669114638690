import React from "react";
import { NOTIFICATION } from "../../../types/notification";

interface CardDataStatsProps {
  notificationData: NOTIFICATION | undefined;
}

/**
 * ViewNotificationCard - A component for displaying the details of a notification.
 *
 * This component is used to view the details of a notification in a read-only format.
 * It displays the notification's creation date, title, and content. The data is passed
 * through the `notificationData` prop, and if no data is provided, it gracefully handles
 * the undefined state by displaying nothing.
 *
 * @param {Object} props - The props object.
 * @param {NOTIFICATION | undefined} props.notificationData - The notification object containing details such as title, content, and created_at date. It can be undefined if no notification data is available.
 * @returns {JSX.Element} - A JSX element displaying the notification details in a card format.
 *
 * Usage:
 * <ViewNotificationCard notificationData={notification} />
 */

const ViewNotificationCard: React.FC<CardDataStatsProps> = ({
  notificationData,
}) => {
  const renderContentWithLineBreaks = (content: string | undefined) => {
    if (!content) return null;
    return content.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="rounded-lg border border-stroke bg-white py-6 px-3.5">
      <div className="mt-2 flex items-end justify-between">
        <div className="w-full ml-5">
          <>
            <div className="w-full mb-4">
              <h2 className="text-font-label-blue font-bold text-xl">
                配信日時
              </h2>
              <p className="text-title-md text-black ">
                {notificationData?.created_at.split("T")[0]}
              </p>
              <div className="divider mt-5"></div>
            </div>

            <div>
              <h2 className="text-font-label-blue font-bold text-xl">
                タイトル
              </h2>
              <p className="text-title-md text-black">
                {notificationData?.title}
              </p>
            </div>

            <div className="divider mt-5"></div>

            <div className="w-full mb-4">
              <h2 className="text-font-label-blue font-bold text-xl">内容</h2>
              <p className="text-title-md text-black">
                {renderContentWithLineBreaks(notificationData?.content)}
              </p>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default ViewNotificationCard;
