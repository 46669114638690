import { STAMP } from "../types/stamps.ts";

/* dummy data */

const stamps: STAMP[] = [
  {
    id: 1,
    uri: "/images/stamps/stamp1.png",
  },
  {
    id: 2,
    uri: "/images/stamps/stamp2.png",
  },
  {
    id: 3,
    uri: "/images/stamps/stamp3.png",
  },
];

export default stamps;
