import React from "react";
import { useParams } from "react-router-dom";
import { TitleWithDivider } from "../../common/TitleWithDivider.tsx";
import UserInformation from "../../components/Forms/UserProfileForm.tsx";
import { error_message } from "../../constants/Errors.tsx";

const ViewCurrentUserPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <div>
        <TitleWithDivider titleText="ユーザ情報参照・編集" useDivider={true} />
        <UserInformation
          id={id != null ? id : error_message.generic.unknown}
        ></UserInformation>
      </div>
    </>
  );
};

export default ViewCurrentUserPage;
